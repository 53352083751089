import {
  FileText,
  FlaskConical,
  Info,
  Rocket,
  Settings2,
  Wrench,
} from 'lucide-react';

export const ManageCustomAgentStatus = [
  {
    label: 'Activated',
    value: 'active',
  },
  {
    label: 'Deactivated',
    value: 'inactive',
  },
  {
    label: 'Draft',
    value: 'draft',
  },
];
export const CustomAgentFormSteps = [
  {
    id: 1,
    title: 'General Info',
    icon: Info,
    slug: 'general-info',
  },
  {
    id: 2,
    title: 'Instruction',
    icon: FileText,
    slug: 'instruction',
  },
  {
    id: 3,
    title: 'Tools',
    icon: Wrench,
    slug: 'tools',
  },
  {
    id: 4,
    title: 'Configuration',
    icon: Settings2,
    slug: 'configuration',
  },
  {
    id: 5,
    title: 'Test',
    icon: FlaskConical,
    slug: 'test',
  },
  {
    id: 6,
    title: 'Deploy',
    icon: Rocket,
    slug: 'deploy',
  },
] as const;

export type TCustomAgentFormSteps = typeof CustomAgentFormSteps;
export type TCustomAgentFormStepSlug =
  typeof CustomAgentFormSteps[number]['slug'];
