'use client';
import useTranslation from 'components/customHooks/useTranslation';
import { Button } from 'libraryV2/ui/button';
import { Input } from 'libraryV2/ui/input';
import { Search, CirclePlusIcon, Download, X } from 'lucide-react';
import { useState, FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'libraryV2/ui/dropdown-menu';
import { useDebounce } from 'components';

interface DataTableToolbarProps {
  selectedStatus: string;
  setSelectedStatus: (val: string) => void;
  setSelectedRecoveredStatus: (val: boolean | undefined) => void;
  setSearchKey: (val: string) => void;
}

export const DataTableToolbar: FC<DataTableToolbarProps> = ({
  selectedStatus,
  setSelectedStatus,
  setSelectedRecoveredStatus,
  setSearchKey,
}) => {
  const { t } = useTranslation();
  const { selectedUserActionFlowData } = useSelector(
    (state: any) => state.workflowAutomation
  );
  const [localSearchKey, setLocalSearchkey] = useState('');
  const [recoveredStatus, setRecoveredStatus] = useState('');
  const [openStatusDropdown, setOpenStatusDropdown] = useState<boolean>(false);
  const [openRecoveredStatusDropdown, setOpenRecoveredStatusDropdown] =
    useState<boolean>(false);
  const status = [
    {
      label: 'Successful',
      value: 'completed',
      textColor: 'text-green-500',
      bgColor: 'bg-green-500',
    },
    {
      label: 'Failed',
      value: 'failed',
      textColor: 'text-red-500',
      bgColor: 'bg-red-500',
    },
    {
      label: 'Ongoing',
      value: 'started',
      textColor: 'text-amber-500',
      bgColor: 'bg-amber-500',
    },
  ];
  const recovered_status = [
    {
      label: 'Recovered',
      value: true,
      textColor: 'text-green-500',
      bgColor: 'bg-green-50',
    },
    {
      label: 'Not Recovered',
      value: false,
      textColor: 'text-textPrimary',
      bgColor: 'bg-gray-50',
    },
  ];

  const debouncedSearchTerm = useDebounce(localSearchKey, 500);

  useEffect(() => {
    setSearchKey(debouncedSearchTerm);
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const closeDropdown = () => {
    setOpenStatusDropdown(false);
    setOpenRecoveredStatusDropdown(false);
  };

  return (
    <div className='flex items-center justify-between'>
      <div className='flex flex-1 items-center space-x-2'>
        <div className='flex relative items-center'>
          <Search className='absolute left-2 mt-1 h-4 w-4 text-zinc-500 transform top-1/2 -translate-y-1/2' />
          <Input
            placeholder={t(
              `Search ${
                selectedUserActionFlowData?.trigger_name === 'Cart Abandoned'
                  ? 'checkout id'
                  : 'order id'
              }`
            )}
            value={localSearchKey}
            onChange={(e) => setLocalSearchkey(e.target.value)}
            className='pl-7 h-8 w-[320px] lg:w-[320px]'
          />
        </div>
        <div>
          <DropdownMenu open={openStatusDropdown} onOpenChange={closeDropdown}>
            <DropdownMenuTrigger
              asChild
              onClick={() => setOpenStatusDropdown(!openStatusDropdown)}
            >
              <div className='flex items-center px-3 py-1 mt-1 rounded-md border border-dotted border-[#DFDFE2] cursor-pointer'>
                <div>
                  <CirclePlusIcon size={15} />
                </div>
                <p className='text-sm font-medium ml-1'>Status</p>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              side='bottom'
              align='start'
              className='shadow-lg bg-white z-30 rounded-md p-0'
            >
              <div className='py-2 px-1'>
                <div className='flex flex-col gap-3 p-1.5'>
                  {status.map((item, index) => (
                    <div key={index} className='flex items-center'>
                      <input
                        type='checkbox'
                        name='active'
                        readOnly
                        id={item.value}
                        checked={selectedStatus === item.value}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelectedStatus(item.value);
                          } else {
                            setSelectedStatus('');
                          }
                        }}
                        className={`text-green-500 outline-none border rounded cursor-pointer focus:ring-0`}
                      />
                      <label
                        htmlFor={item.value}
                        className='ml-2 cursor-pointer'
                      >
                        <div
                          className={`border border-border  py-0.5 px-2 rounded-md text-sm font-medium inline-flex items-center gap-1 ${item.textColor}`}
                        >
                          <div
                            className={`w-2 h-2 ${item.bgColor} rounded-full`}
                          ></div>
                          <span>{item.label}</span>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {!!selectedStatus && (
                <div
                  className='border-t p-2 flex justify-center cursor-pointer'
                  onClick={() => {
                    setSelectedStatus('');
                    setOpenStatusDropdown(!openStatusDropdown);
                  }}
                >
                  <p className=''>Clear Filters</p>
                </div>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        {selectedUserActionFlowData?.trigger_name === 'Cart Abandoned' && (
          <div>
            <DropdownMenu
              open={openRecoveredStatusDropdown}
              onOpenChange={closeDropdown}
            >
              <DropdownMenuTrigger
                asChild
                onClick={() =>
                  setOpenRecoveredStatusDropdown(!openRecoveredStatusDropdown)
                }
              >
                <div className='flex items-center px-3 py-1 mt-1 rounded-md border border-dotted border-[#DFDFE2] cursor-pointer'>
                  <div>
                    <CirclePlusIcon size={15} />
                  </div>
                  <p className='text-sm font-medium ml-1'>Recovered status</p>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                side='bottom'
                align='start'
                className='shadow-lg bg-white z-30 rounded-md p-0'
              >
                <div className='py-2 px-1'>
                  <div className='flex flex-col gap-3 p-1.5'>
                    {recovered_status.map((item, index) => (
                      <div key={index} className='flex items-center'>
                        <input
                          type='checkbox'
                          name='active'
                          readOnly
                          id={item.label}
                          checked={recoveredStatus === item.label}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setRecoveredStatus(item.label);
                              setSelectedRecoveredStatus(item.value);
                            } else {
                              setRecoveredStatus('');
                              setSelectedRecoveredStatus(undefined);
                            }
                          }}
                          className={`text-green-500 outline-none border rounded cursor-pointer focus:ring-0`}
                        />
                        <label
                          htmlFor={item.label}
                          className='ml-2 cursor-pointer'
                        >
                          <div
                            className={`border border-border  py-0.5 px-2 rounded-md text-sm font-medium inline-flex items-center ${item.textColor} ${item.bgColor}`}
                          >
                            {item.label}
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                {!!recoveredStatus && (
                  <div
                    className='border-t p-2 flex justify-center cursor-pointer'
                    onClick={() => {
                      setRecoveredStatus('');
                      setSelectedRecoveredStatus(undefined);
                      setOpenRecoveredStatusDropdown(
                        !openRecoveredStatusDropdown
                      );
                    }}
                  >
                    <p className=''>Clear Filters</p>
                  </div>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}

        {(!!selectedStatus || !!recoveredStatus) && (
          <Button
            variant={'ghost'}
            onClick={() => {
              setSelectedStatus('');
              setRecoveredStatus('');
              setSelectedRecoveredStatus(undefined);
            }}
            className='flex  gap-2 items-center bg-zinc-100 h-8 px-2.5 py-1.5'
          >
            <X className='w-4 h-4' />
            <span className='text-xs text-textPrimary'>Clear Filter</span>
          </Button>
        )}
      </div>
      <div>
        <Button size='sm' variant='outline' className='flex gap-2'>
          <Download className='w-3.5 h-3.5' />
          <span className='font-medium text-xs text-textPrimary'>Export</span>
        </Button>
      </div>
    </div>
  );
};
