import { ICustomAgentFormSteps } from 'pages/raven/interface';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { toast } from 'libraryV2/ui/use-toast';
import { Button } from 'libraryV2/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'libraryV2/ui/form';
import { Input } from 'libraryV2/ui/input';
import { FC } from 'react';
import { cn } from 'libraryV2/utils';
import { Textarea } from 'libraryV2/ui/textarea';
import { useCreateCustomAgent } from 'pages/raven/hooks/useCreateCustomAgent';

const InstructionFormSchema = z.object({
  backstory: z.string().min(1, {
    message: 'This field is required.',
  }),
  role: z.string().min(1, {
    message: 'This field is required.',
  }),
});
interface InstructionProps {
  step: ICustomAgentFormSteps;
  onNextStep: () => void;
  onPreviousStep: () => void;
}
export const Instruction: FC<InstructionProps> = ({
  step,
  onNextStep,
  onPreviousStep,
}) => {
  const { customAgentFormData, updateCustomAgentFormData, updateCustomAgent } =
    useCreateCustomAgent();

  const form = useForm<z.infer<typeof InstructionFormSchema>>({
    resolver: zodResolver(InstructionFormSchema),
    mode: 'onChange',
    defaultValues: async () => ({
      backstory: customAgentFormData?.backstory || '',
      role: customAgentFormData?.role || '',
    }),
  });

  function onSubmit(data: z.infer<typeof InstructionFormSchema>) {
    updateCustomAgent({
      instruction: data?.backstory,
      role: data?.role,
    })
      .then((result) => {
        onNextStep();
        updateCustomAgentFormData({ ...customAgentFormData, ...data });
        toast({
          toastType: 'success',
          title: 'Save Changes',
          description: (
            <p className='text-textPrimary'>
              Your changes have been saved successfully.
            </p>
          ),
        });
      })
      .catch((er) => {
        toast({
          toastType: 'destructive',
          title: 'Failed to save',
          description: (
            <p className='text-textPrimary'>Your changes is not saved.</p>
          ),
        });
      });
  }

  const onDraftSave = () => {
    const data = form.getValues();
    updateCustomAgent({
      instruction: data?.backstory,
      role: data?.role,
    })
      .then((result) => {
        updateCustomAgentFormData({ ...customAgentFormData, ...data });
        toast({
          toastType: 'success',
          title: 'Draft Saved',
          description: (
            <p className='text-textPrimary'>
              Your changes have been saved as Draft successfully.
            </p>
          ),
        });
      })
      .catch((er) => {
        toast({
          toastType: 'destructive',
          title: 'Failed to save',
          description: (
            <p className='text-textPrimary'>Your changes is not saved.</p>
          ),
        });
      });
  };

  const renderInstructionForm = () => {
    return (
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className='w-full space-y-4'
        >
          <FormField
            control={form.control}
            name='backstory'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Backstory
                  <span className='text-red-500'>*</span>
                </FormLabel>
                <FormControl>
                  <Textarea
                    style={{ resize: 'vertical' }}
                    autoResize={false}
                    placeholder='Describe the purpose and background of this custom agent.'
                    className='border-border shadow-sm focus:border-green-500 min-h-[80px] focus-visible:ring-0'
                    {...field}
                  />
                </FormControl>
                <FormDescription
                  className={cn('text-textSecondary font-normal', {
                    hidden: !!form.formState.errors[field.name],
                  })}
                >
                  E.g., A virtual assistant that prioritizes tickets based on
                  urgency and assigns them to the best-suited agent.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='role'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Role <span className='text-red-500'>*</span>
                </FormLabel>
                <FormControl>
                  <Input placeholder='Describe the role' {...field} />
                </FormControl>
                <FormDescription
                  className={cn('text-textSecondary font-normal', {
                    hidden: !!form.formState.errors[field.name],
                  })}
                >
                  Define what this agent is expected to do. Be specific and
                  align it with the backstory to ensure clarity of purpose.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className='w-full flex justify-between pt-2'>
            <div>
              <Button
                onClick={() => onPreviousStep()}
                type='button'
                variant={'ghost'}
                className='bg-background-hover'
              >
                Back
              </Button>
            </div>
            <div className='flex gap-2'>
              <Button type='button' onClick={onDraftSave} variant={'outline'}>
                Save as Draft
              </Button>
              <Button
                type='submit'
                disabled={!form.formState.isValid}
                className={'disabled:opacity-75'}
              >
                Save & Continue
              </Button>
            </div>
          </div>
        </form>
      </Form>
    );
  };

  const renderFormHeader = () => {
    return (
      <div>
        <h2 className='font-bold text-xl mb-1.5'>Instructions</h2>
        <p className='text-sm text-textSecondary'>
          Define the backstory and responsibilities to shape your custom agent's
          role and actions effectively.
        </p>
      </div>
    );
  };

  return (
    <div
      data-testid='raven-general-info-section'
      className='flex flex-col gap-6 w-full h-auto flex-1 max-w-2xl mx-auto pt-6'
    >
      {renderFormHeader()}
      <hr />
      {renderInstructionForm()}
    </div>
  );
};
