import { cn } from 'libraryV2/utils';
import { Circle, CircleCheck } from 'lucide-react';

import { ICustomAgentFormSteps } from 'pages/raven/interface';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

interface StepperProps {
  steps: ICustomAgentFormSteps[];
  currentStep: number;
  stepCompleted: number;
  onStepSelect: (id: number) => void;
}

export const CreateCustomAgentSteps = ({
  steps,
  currentStep,
  stepCompleted,
  onStepSelect,
}: StepperProps) => {
  const renderStepStatusIcon = (step: ICustomAgentFormSteps) => {
    if (step?.isDone) {
      return <CheckCircleIcon className='text-primary w-5 h-5 flex-shrink-0' />;
    }
    if (step?.id === currentStep) {
      return <CircleCheck className='text-primary w-5 h-5 flex-shrink-0' />;
    }
    return (
      <Circle className='text-textPrimary-disable w-5 h-5 flex-shrink-0' />
    );
  };

  return (
    <div className='bg-white relative raven-step-container'>
      <div className='steps'>
        {steps.map((step, _) => {
          const shouldDisableButton =
            (!step?.isDone || step?.slug === 'tools') &&
            step?.id !== stepCompleted + 1;

          return (
            <div
              key={step.id + _ + step.title}
              className={cn('single-step flex gap-3', {
                'step-done': !!step.isDone,
              })}
            >
              {renderStepStatusIcon(step)}
              <button
                onClick={() => onStepSelect(step?.id)}
                // temporary disabling tools step
                disabled={shouldDisableButton}
                className={cn(
                  'icon-btn  hover:bg-background-hover w-full px-3 py-2 rounded',
                  {
                    'bg-background-hover': currentStep === step?.id,
                    'cursor-not-allowed': shouldDisableButton,
                    // !step.isDone && currentStep !== step.id,
                  }
                )}
              >
                <div
                  className={cn('flex gap-2 items-center h-full', {
                    'text-textPrimary-disable': shouldDisableButton,
                    // !step.isDone && currentStep !== step.id,
                  })}
                >
                  <step.icon className='w-5 h-5' />
                  <span className='text-sm font-medium'>{step.title}</span>
                </div>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
