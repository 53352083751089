import { Separator } from 'libraryV2/ui/separator';
import { IFormattedTestMessage } from 'pages/raven/interface';
import { FC } from 'react';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'libraryV2/ui/sheet';
import {
  Activity,
  ArrowUpDown,
  Bot,
  LayoutList,
  Lightbulb,
  MessageSquareText,
} from 'lucide-react';
import { startCase } from 'lodash';

interface TestMessageSystemUpdateProps {
  systemData: IFormattedTestMessage['systemUpdates'];
}

export const TestMessageSystemUpdate: FC<TestMessageSystemUpdateProps> = ({
  systemData,
}) => {
  const getThoughtProcessIcon = (key: string) => {
    switch (key) {
      case 'thought':
        return <Lightbulb className='w-5 h-5 text-textPrimary flex-shrink-0' />;
      case 'action':
        return <Activity className='w-5 h-5 text-textPrimary flex-shrink-0' />;
      case 'observation':
        return (
          <LayoutList className='w-5 h-5 text-textPrimary flex-shrink-0' />
        );
      case 'response':
        return (
          <MessageSquareText className='w-5 h-5 text-textPrimary flex-shrink-0' />
        );
      case 'return_direct':
        return (
          <ArrowUpDown className='w-5 h-5 text-textPrimary flex-shrink-0' />
        );
      default:
        return <Bot className='w-5 h-5 text-textPrimary flex-shrink-0' />;
    }
  };
  const renderSingleThoughtProcess = (
    thoughtProcess: Record<string, string>
  ) => {
    return Object.entries(thoughtProcess)?.map(([key, value], _) => {
      const isStringValue = typeof value === 'string';

      return (
        <div className='flex gap-2' key={_ + key}>
          <div className='p-1 rounded-md border border-border h-[fit-content]'>
            {getThoughtProcessIcon(key)}
          </div>
          <div className='space-y-1'>
            <p className='text-xs font-normal text-textSecondary '>
              {startCase(key)}
            </p>
            <p className='border rounded-md border-border whitespace-pre-line p-3 text-sm font-medium'>
              {isStringValue ? value : JSON.stringify(value, null, 2)}
            </p>
          </div>
        </div>
      );
    });
  };
  if (!systemData) {
    return null;
  }
  return (
    <Sheet>
      <SheetTrigger asChild disabled={!systemData?.length}>
        <div className='w-full flex items-center gap-2'>
          <Separator />
          <button className='flex-shrink-0 py-0.5 text-xs font-medium px-1.5 border border-border rounded-lg'>
            System Updates
          </button>
          <Separator />
        </div>
      </SheetTrigger>
      <SheetContent className='bg-white text-textPrimary overflow-y-auto'>
        <SheetHeader>
          <SheetTitle>System Updates</SheetTitle>
          <SheetDescription>
            See a detailed breakdown of every step your custom agent took to
            process and respond to the query.
          </SheetDescription>
        </SheetHeader>
        <div className='flex flex-col gap-4 py-4'>
          {systemData?.map((data) => {
            // @ts-ignore
            return renderSingleThoughtProcess(data);
          })}
        </div>
      </SheetContent>
    </Sheet>
  );
};
