import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from 'libraryV2/ui/sheet';
import {Button} from 'libraryV2/ui/button';
import DatalabForm from './DatalabForm';
import { TDatalabFormModes } from 'pages/datalab/export';
import useTranslation from 'components/customHooks/useTranslation';
import { Edit, Trash2 } from 'lucide-react';

interface SiteSheetProps {
  onClose: () => void;
  header: string;
  isOpen: boolean;
  datalabEntries: any;
  datalabShape: any;
  mode: TDatalabFormModes;
  onEdit?: () => void;
  onDelete?: () => void;
}

const DatalabSideSheet = ({
  onClose,
  header,
  isOpen = false,
  datalabEntries,
  datalabShape,
  mode,
  onEdit,
  onDelete
}: SiteSheetProps) => {
  const { t } = useTranslation();
  return (
    <Sheet open={isOpen} onOpenChange={onClose} modal={true}>
      <SheetContent
        side='right'
        className='bg-white sm:max-w-[470px] sm:w-[470px] overflow-auto'
      >
        <SheetHeader>
          <SheetTitle className='text-zinc-900 rtl:text-right'>
            {t(header)}
          </SheetTitle>
        </SheetHeader>
        <div className='mt-4'>
          {mode === 'CREATE' ? (
            <DatalabForm
              onClose={onClose}
              formAction='CREATE'
              datalabShape={datalabShape}
            />
          ) : (
            <DatalabForm
              onClose={onClose}
              formAction={mode}
              datalabEntries={datalabEntries}
              datalabShape={datalabShape}
            />
          )}
        </div>
        {(mode === 'VIEW' || mode === 'EDIT') && (
          <div className='mt-8 flex items-center justify-between'>
          <Button
            variant={'outline'}
            className='bg-[#FFF0F0] text-[#ff0000] hover:text-[#ff0000] border-0 shadow-none'
            onClick={onDelete} 
          >
            <Trash2 className='mr-2 h-4 w-4' />
            Delete
          </Button>
          {mode === 'VIEW' && (
            <Button variant={'outline'} onClick={onEdit}>
            <Edit className='mr-2 h-4 w-4' />
            Edit Entry
          </Button>
          )}
        </div>
        )}
        
      </SheetContent>
    </Sheet>
  );
};

export default DatalabSideSheet;
