import { useAutomationWorkflow, dayjs, useSelector } from '../../export';
import { endOfDay, subDays } from 'date-fns';
import useThrottledEffect from './hooks/useThrottleEffect';
import AutomationDashboardHeader from './component/AutomationDahsboardHeader';
import AutomationMetriceCards from './component/AutomationMetricsCards';
import AutomationTable from './component/automationTable';
import { columns } from './component/automationTable/component/Column';
import useAutomationDashboard from './hooks/useAutomationDashboard';
import { useState, useMemo, useEffect } from 'react';
import { DateRange } from 'react-day-picker';
import { calculateDurationInDays } from 'pages/automationWorkflow/utility/function';
import { AutomationDashboardLoader } from './component/AutomationDashboardLoader';
import notFoundImage from '../../../../assets/icons/emptyPageIcon.svg';
import integrationIcon from '../../../../assets/icons/integrationIcon.svg';
import useTranslation from 'components/customHooks/useTranslation';
import { Plus, LayoutGrid } from 'lucide-react';
import { Button } from 'libraryV2/ui/button';
import { navigate } from '@reach/router';

const AutomationDashboard = () => {
  const { t } = useTranslation();
  const { integratedEcommerceList } = useSelector(
    (state: any) => state.workflowAutomation
  );
  const { selectedProject } = useSelector((state: any) => state.dashboard);
  const { userActionFlowList, clearStateData } = useAutomationWorkflow();
  const [dateRange, setDateRange] = useState({
    from: endOfDay(subDays(new Date(), 7)),
    to: new Date(),
  });
  const [selectedTriggerTypes, setSelectedTriggerTypes] = useState<string[]>(
    []
  );
  const [selectedSources, setSelectedSources] = useState<number[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<boolean | undefined>();
  const [searchkey, setSearchKey] = useState('');
  const { fetchUserActionFlowData, fetchIntegratedEcommerceData } =
    useAutomationDashboard();
  const [limit, setLimit] = useState<number>(10);
  const [cacheOff, setCachOff] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [firstRender, setFirstRender] = useState<boolean>(true);

  useEffect(() => {
    if (!firstRender) {
      setOffset(-1);
    }
    // eslint-disable-next-line
  }, [
    dateRange,
    selectedTriggerTypes,
    selectedSources,
    searchkey,
    selectedStatus,
    limit,
    cacheOff,
  ]);

  useThrottledEffect(
    () => {
      if (offset < 0) {
        setOffset(0);
      } else {
        setLoading(true);
        fetchIntegratedEcommerceData();
        fetchUserActionFlowData({
          from: dayjs(dateRange.from).toISOString(),
          to: dayjs(dateRange.to).toISOString(),
          sources: selectedSources,
          triggerTypes: selectedTriggerTypes,
          status: selectedStatus,
          cacheOff: cacheOff,
          userFlowName: searchkey,
          limit: limit,
          offset: offset > 0 ? offset || 0 : 0,
        })
          .then(() => {
            setFirstRender(false);
            setLoading(false);
          })
          .catch(() => {
            setFirstRender(false);
            setLoading(false);
          });
      }
    },
    [offset],
    1500
  );

  const getPreviousDateRange = (range: DateRange) => {
    const from = dayjs(range.from);
    const to = dayjs(range.to);

    // Calculate the duration of the current range in days
    const duration = calculateDurationInDays(range); // Inclusive of both dates

    // Subtract the duration from the current range to shift it backward
    const previousFrom = from.subtract(duration, 'day');
    const previousTo = to.subtract(duration, 'day');

    // Format the previous date range
    return `${previousFrom.format('MMM DD')} - ${previousTo.format(
      'MMM DD, YYYY'
    )}`;
  };

  const isRenderNoEntriesFound = useMemo(() => {
    return (
      userActionFlowList?.length <= 0 &&
      !selectedStatus &&
      !searchkey &&
      !loading &&
      selectedTriggerTypes.length <= 0 &&
      selectedSources.length <= 0
    );
  }, [
    userActionFlowList,
    searchkey,
    selectedSources,
    loading,
    selectedTriggerTypes,
    selectedStatus,
  ]);

  const renderNoEcommerceConnected = () => {
    return (
      <div className='flex flex-col items-center justify-center  text-center min-h-[93vh]'>
        <img
          src={integrationIcon}
          alt='integrationIcon'
          className='h-24 mb-4'
        />
        <h3 className='mb-2 text-lg font-semibold text-textPrimary'>
          {t('No Ecommerce Integrated')}
        </h3>
        <span className='mb-2 text-zinc-500 font-medium text-sm'>
          {t('Please integrate your ecommerce platform to')}
        </span>
        <span className='mb-2 text-zinc-500 font-medium'>
          {t('MyAlice to create automations.')}
        </span>
        <div className='flex items-center justify-center mt-3'>
          <div>
            <Button
              onClick={() => navigate(`/integrations/integrated`)}
              className='ml-2 flex items-center font-normal border-border bg-white text-textPrimary px-4 rounded-md hover:bg-white hover:text-textPrimary'
            >
              <LayoutGrid className='w-4 h-4 ltr:mr-2 rtl:ml-2 mr-2' />
              {t('Go to Integrations')}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderNoEntriesFound = () => {
    return (
      <div className='flex flex-col items-center justify-center  text-center min-h-[93vh]'>
        <img src={notFoundImage} alt='emptySearchIcon' className='h-24 mb-4' />
        <h3 className='mb-2 text-lg font-semibold text-textPrimary'>
          {t('No Automations Found')}
        </h3>
        <span className='mb-2 text-zinc-500 font-medium'>
          {t('There is no automations found in the selected time frame.')}
        </span>
        <span className='mb-2 text-zinc-500 font-medium'>
          {t('Start by creating new automation.')}
        </span>
        <div className='flex items-center justify-center mt-3'>
          <div>
            <Button
              disabled={
                !integratedEcommerceList || integratedEcommerceList?.length < 1
              }
              onClick={() => {
                clearStateData();
                navigate(
                  `/projects/${selectedProject?.id}/workflow-automation/create`
                );
              }}
              className='ml-2 flex items-center font-normal bg-primary text-white px-4 rounded-md hover:bg-primary'
            >
              <Plus className='w-4 h-4 text-white ltr:mr-2 rtl:ml-2 mr-2' />
              {t('Create New Automation')}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderAutomationDashboard = () => {
    return (
      <>
        <div className='px-5 mt-4'>
          <p className='text-textPrimary text-base font-semibold'>
            Results from the automations
          </p>
          <p className='text-textSecondary'>
            {dayjs(dateRange.from).format('MMM DD')} -{' '}
            {dayjs(dateRange.to).format('MMM DD, YYYY')} compared to{' '}
            {getPreviousDateRange(dateRange)}
          </p>
        </div>
        <AutomationMetriceCards
          dateRange={dateRange}
          setCachOff={setCachOff}
          loading={loading}
        />
        <div className='px-5 py-5 bg-white'>
          <AutomationTable
            data={userActionFlowList}
            columns={columns}
            selectedTriggerTypes={selectedTriggerTypes}
            setSelectedTriggerTypes={setSelectedTriggerTypes}
            selectedSources={selectedSources}
            setSelectedSources={setSelectedSources}
            setLimit={setLimit}
            limit={limit}
            setSelectedStatus={setSelectedStatus}
            setSearchKey={setSearchKey}
            offset={offset}
            setOffset={setOffset}
            loading={loading}
          />
        </div>
      </>
    );
  };

  return (
    <div className='relative w-full h-[100vh] bg-white border-l'>
      <div className='sticky top-0 z-10 bg-white'>
        <AutomationDashboardHeader
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      </div>
      <>
        {firstRender ? (
          <AutomationDashboardLoader />
        ) : !integratedEcommerceList || integratedEcommerceList?.length < 1 ? (
          renderNoEcommerceConnected()
        ) : isRenderNoEntriesFound ? (
          renderNoEntriesFound()
        ) : (
          renderAutomationDashboard()
        )}
      </>
    </div>
  );
};

export default AutomationDashboard;
