import { ICustomAgentFormSteps } from 'pages/raven/interface';
import { FC } from 'react';

interface ToolsProps {
  step: ICustomAgentFormSteps;
  onNextStep: () => void;
  onPreviousStep: () => void;
}

export const Tools: FC<ToolsProps> = ({ onNextStep, onPreviousStep }) => {
  onNextStep();
  return <div>Tools</div>;
};
