import Axios from 'axios';
import Compressor from 'compressorjs';
import { getYear } from 'date-fns';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import { toaster } from 'evergreen-ui';
import { range } from 'lodash';
import mixpanel from 'mixpanel-browser';
import emailIconSet from '../assets/icons/channelIconSet/email.svg';
import emailIcon from '../assets/images/emailIcon.svg';
import instaCommentIcon from '../assets/images/insta-comment-outline.svg';
import instaMessageIcon from '../assets/images/insta-message-outline.svg';
import lineIcon from '../assets/images/line-outline.svg';
import telegramIcon from '../assets/images/telegram-outline.svg';
import telegramGroupIcon from '../assets/images/telegramGroup.svg';
import appIcon from '../old/assets/images/appsIcon.svg';
import defaultPlatform from '../old/assets/images/default_platform.svg';
import facebookFeed from '../old/assets/images/facebook.svg';
import webchatIcon from '../old/assets/images/inconnect.svg';
import AliceLogo from '../old/assets/images/inconnect_logo.svg';
import messengerIcon from '../old/assets/images/messenger.svg';
import priorityHighIcon from '../old/assets/images/priority_high.svg';
import priorityLowIcon from '../old/assets/images/priority_low.svg';
import priorityMedIcon from '../old/assets/images/priority_med.svg';
import viberIcon from '../old/assets/images/viber.svg';
import whatappsIcon from '../old/assets/images/whatsapp.svg';
import config from './config';
import store from './stores';

// import Icon from '../components/globalComponents/Icon';
// import facebookMessengerChannelIcon from '../old/pages/settings/assets/icons/facebook_messenger.svg';
// import facebookFeedChannelIcon from '../old/pages/settings/assets/icons/facebook_feed.svg';
// import websiteChannelIcon from '../old/pages/settings/assets/icons/website.svg';
// import appChannelIcon from '../old/pages/settings/assets/icons/app.svg';
// import viberChannelIcon from '../old/pages/settings/assets/icons/viber.svg';
// import whatsappChannelIcon from '../old/pages/settings/assets/icons/whatsapp_channel.svg';

//alert
import dangerIcon from '../old/assets/images/alertImages/danger.svg';
import successIcon from '../old/assets/images/alertImages/success.svg';
import warningIcon from '../old/assets/images/alertImages/warning.svg';

//outlined set
import whatsappDashboardIcon from '../assets/icons/channelIconSet/whatsapp_messenger.svg';
import appDashboardIcon from '../pages/dashboard/assets/images/icons/app.svg';
import fbFeedDashboardIcon from '../pages/dashboard/assets/images/icons/fb-feed.svg';
import fbMessengerDashboardIcon from '../pages/dashboard/assets/images/icons/fb-messenger.svg';
import livechatDashboardIcon from '../pages/dashboard/assets/images/icons/livechat_messenger.svg';
import viberDashboardIcon from '../pages/dashboard/assets/images/icons/viber.svg';
import webchatDashboardIcon from '../pages/dashboard/assets/images/icons/webchat.svg';

import defaultAvatar from '../assets/images/defaultAvatar.svg';
import downIcon from '../pages/reporting/assets/images/reportingDown.svg';
import upIcon from '../pages/reporting/assets/images/reportingUp.svg';

import moment from 'moment';

//isRead Icon
import React, { useEffect } from 'react';
import Linkify from 'react-linkify';
import isReadIcon from '../old/pages/crm/assets/icon/readIcon.svg';
import isUnreadIcon from '../old/pages/crm/assets/icon/unreadIcon.svg';

import {
  BarChart4,
  BotMessageSquare,
  Cable,
  CableIcon,
  DatabaseZap,
  LayoutGrid,
  Megaphone,
  MessageSquare,
  Users,
  ChartNoAxesCombined,
  BrainCog,
  Workflow,
} from 'lucide-react';

// import {
//   ChatBubbleLeftRightIcon,
//   UserGroupIcon,
//   CircleStackIcon,
//   SquaresPlusIcon,
// } from '@heroicons/react/24/solid';

//this is main icon set rest will be replaced by this set
import appMessengerIconSet from './../assets/icons/channelIconSet/app_messenger.svg';
import facebookFeedIconSet from './../assets/icons/channelIconSet/facebook_feed.svg';
import facebookMessengerIconSet from './../assets/icons/channelIconSet/facebook_messenger.svg';
import instagramFeedIconSet from './../assets/icons/channelIconSet/instagram_feed.svg';
import instagramMessengerIconSet from './../assets/icons/channelIconSet/instagram_messenger.svg';
import lineMessengerIconSet from './../assets/icons/channelIconSet/line_messenger.svg';
import telegramGroupIconSet from './../assets/icons/channelIconSet/telegram_group.svg';
import telegramMessengerIconSet from './../assets/icons/channelIconSet/telegram_messenger.svg';
import viberMessengerIconSet from './../assets/icons/channelIconSet/viber_messenger.svg';
import webChatBubbleOvalLeftEllipsisIconSet from './../assets/icons/channelIconSet/webchat_messenger.svg';
import whatsappMessengerIconSet from './../assets/icons/channelIconSet/whatsapp_messenger.svg';
import witIconSet from './../assets/icons/channelIconSet/wit.svg';

import zidIconSet from '../assets/icons/channelIconSet/zidIcon.svg'; //' ../assets/icons/marketplace/zidIcon.svg';
import sallaIconSet from './../assets/icons/channelIconSet/salla.svg';
import shopifyIconSet from './../assets/icons/channelIconSet/shopify.svg';
import wooCommerceIconSet from './../assets/icons/channelIconSet/woocommerce.svg';

import {
  CircledEmailIcon,
  CircledFaceBookIcon,
  CircledFaceBookMessengerIcon,
  CircledInstagramFeedIcon,
  CircledInstagramMessengerIcon,
  CircledLineIcon,
  CircledLiveChatBubbleOvalLeftEllipsisIcon,
  CircledMobileIcon,
  CircledTelegramGroupIcon,
  CircledTelegramIcon,
  CircledViberIcon,
  CircledWhatsappBspIcon,
  CircledWhatsappIcon,
} from 'assets/icons/circledChannelIconSet';
import {
  AppMessengerIconWithColor,
  DefaultIconWithColor,
  FacebookIconWithColor,
  FacebookMessengerIconWithColor,
  InstagramIconWithColor,
  InstagramMessengerIconWithColor,
  LineIconWithColor,
  TelegramIconWithColor,
  ViberIconWithColor,
  WebChatBubbleOvalLeftEllipsisIconWithColor,
  WhatsappIconWithColor,
} from 'pages/integration/assets/icons/socialIcons';
import brainFromAiceIconSet from './../assets/icons/channelIconSet/brain_from_alice.svg';
import intantIconSet from './../assets/icons/channelIconSet/intnt.svg';

// import bigCommerceIconSet from './../assets/icons/channelIconSet/big_commerce.svg';
// import dialogFlowIconSet from './../assets/icons/channelIconSet/dialogflow.svg';
// import betterdocsIconSet from './../assets/icons/channelIconSet/betterdocs.svg';
// import magentoIconSet from './../assets/icons/channelIconSet/magento.svg';
// import dokanIconSet from './../assets/icons/channelIconSet/dokan.svg';

import {
  EnglandIcon,
  PortugalIcon,
  SaudiArabicIcon,
  SpanishIcon,
} from 'assets/icons/countryFlag';
import { cn } from 'libraryV2/utils';

export const settingsNavData = [
  {
    title: 'User Settings',
    globalAccess: true,
    data: [
      {
        id: 0,
        title: 'User Information',
        link: '/settings/profile#user-settings',
        icon: 'user',
        itemGlobalAccess: true,
        isPro: false,
      },
      {
        id: 1,
        title: 'Change Password',
        link: '/settings/profile#password',
        icon: 'key',
        itemGlobalAccess: true,
        isPro: false,
      },
    ],
  },
  {
    title: 'Inbox Settings',
    globalAccess: true,
    data: [
      {
        id: 27,
        title: 'Ticket Configuration',
        link: '/settings/ticket-config',
        icon: 'ticketConfig',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings_ticket_configuration',
          writeAccess: 'write:settings_ticket_configuration',
        },
      },
      {
        id: 28,
        title: 'Inbox Preferences',
        link: '/settings/inbox-preferences',
        icon: 'inboxPreferences',
        itemGlobalAccess: true,
        isPro: false,
        permission: {
          readAccess: 'read:conversation',
          writeAccess: 'write:conversation',
        },
      },
      {
        id: 29,
        title: 'SLA Configurations',
        link: '/settings/sla-settings',
        icon: 'slaConfiguration',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings_sla_configuration',
          writeAccess: 'write:settings_sla_configuration',
        },
      },
      {
        id: 15,
        title: 'Notification Settings',
        link: '/settings/notification',
        icon: 'notifications',
        itemGlobalAccess: true,
        isPro: false,
        permission: {
          readAccess: 'read:conversation',
          writeAccess: 'write:conversation',
        },
      },
    ],
  },
  {
    title: 'Chat Settings',
    globalAccess: true,
    data: [
      {
        id: 20,
        title: 'Dashboard Preferences',
        link: '/settings/dashboard-preferences',
        icon: 'inbox',
        itemGlobalAccess: true,
        isPro: false,
        permission: {
          readAccess: 'read:settings',
          writeAccess: 'write:settings',
        },
      },
      {
        id: 16,
        title: 'Business Hours',
        link: '/settings/business-hour',
        icon: 'time',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings_business_hour',
          writeAccess: 'write:settings_business_hour',
        },
      },
      {
        id: 21,
        title: 'Chatbot Settings',
        link: '/settings/chatbot-settings',
        icon: 'bot',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings',
          writeAccess: 'write:settings',
        },
      },
      {
        id: 17,
        title: 'Tags',
        link: '/settings/tags',
        icon: 'tag',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings_tag',
          writeAccess: 'write:settings_tag',
        },
      },
      {
        id: 19,
        title: 'Saved Replies',
        link: '/settings/saved-replies',
        icon: 'chat',
        itemGlobalAccess: false,
        isPro: true,
        permission: {
          readAccess: 'read:settings_saved_reply',
          writeAccess: 'write:settings_saved_reply',
        },
      },

      // {
      //   id: 17,
      //   title: 'Holiday & Day Off',
      //   link: '/settings/holiday-dayoff',
      //   icon: 'history',
      //   itemGlobalAccess: true,
      //   isPro: false,
      //   permission: {
      //     readAccess: 'read:settings',
      //     writeAccess: 'write:settings',
      //   },
      // },
    ],
  },
  {
    title: 'Team Settings',
    globalAccess: false,
    data: [
      {
        id: 2,
        title: 'Team Information',
        link: '/settings/team-info',
        icon: 'projects',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings_team_information',
          writeAccess: 'write:settings_team_information',
        },
      },
      {
        id: 3,
        title: 'User Management',
        link: '/settings/team-user',
        icon: 'user',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings_user_management',
          writeAccess: 'write:settings_user_management',
        },
      },
      {
        id: 4,
        title: 'Group Management',
        link: '/settings/team-group',
        icon: 'group-objects',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings_group_management',
          writeAccess: 'write:settings_group_management',
        },
      },
      // {
      //   id: 14,
      //   title: 'Marketplace',
      //   link: '/settings/marketplace',
      //   icon: 'shop',
      //   itemGlobalAccess: false,
      //   isPro: false,
      //   permission: {
      //     readAccess: 'read:settings',
      //     writeAccess: 'write:settings',
      //   },
      // },
    ],
  },
  {
    title: 'Billing Settings',
    globalAccess: false,
    data: [
      {
        id: 7,
        title: 'Plan & Billing',
        link: '/settings/billing',
        icon: 'credit-card',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings',
          writeAccess: 'write:settings',
        },
      },
      // {
      //   id: 8,
      //   title: 'Payment Method',
      //   link: '/settings/payment-method',
      //   icon: 'credit-card',
      //   itemGlobalAccess: false,
      //   isPro: false,
      // },
      // {
      //   id: 9,
      //   title: 'Payment History',
      //   link: '/settings/payment-histories',
      //   icon: 'history',
      //   itemGlobalAccess: false,
      //   isPro: false,
      // },
    ],
  },
  {
    title: 'Developer Settings',
    globalAccess: false,
    data: [
      {
        id: 18,
        title: 'Webhook',
        link: '/settings/webhook',
        icon: 'console',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings',
          writeAccess: 'write:settings',
        },
      },
    ],
  },
];

export const navBars3Icons = {
  1: MessageSquare,
  2: LayoutGrid,
  3: BotMessageSquare,
  4: Users,
  5: BarChart4,
  6: DatabaseZap,
  7: CableIcon,
  9: Megaphone,
  10: Workflow,
  16: ChartNoAxesCombined,
  25: BrainCog,
};

// export const navBars3Icons = {
//   1: ChatBubbleLeftRightIcon,
//   2: SquaresPlusIcon,
//   3: ({ className }) => <Icon name='bot' className={className} />,
//   4: UserGroupIcon,
//   5: ({ className }) => <Icon name='chart-line' className={className} />,
//   6: CircleStackIcon,
//   7: ({ className }) => <Icon name='api' className={className} />,
//   9: ({ className }) => <Icon name='broadcast' className={className} />,
//   10: ({ className }) => (
//     <Icon name='workflow-automation' className={className} />
//   ),
// };

export const navMenu = [
  {
    id: 5,
    name: 'Reports',
    icon: BarChart4,
    link: 'reporting',
    global: false,
    isPro: false,
    // amplitudeEventName: 'Clicked on Reporting',
    permission: {
      readAccess: 'read:analytics',
      writeAccess: 'write:analytics',
    },
  },
  {
    id: 16,
    name: 'Reports(beta)',
    icon: ChartNoAxesCombined,
    link: 'reportingV2',
    global: false,
    isPro: false,
    // amplitudeEventName: 'Clicked on Reporting',
    permission: {
      readAccess: 'read:analytics',
      writeAccess: 'write:analytics',
    },
  },
  {
    id: 1,
    name: 'Inbox',
    icon: MessageSquare,
    link: 'inbox',
    global: false,
    isPro: false,
    // amplitudeEventName: 'Clicked on Inbox',
    permission: {
      readAccess: 'read:conversation',
      writeAccess: 'write:conversation',
    },
  },
  {
    id: 9,
    name: 'Broadcast',
    icon: Megaphone,
    link: 'broadcast',
    global: false,
    isPro: true,
    // amplitudeEventName: 'Clicked on Broadcast',
    permission: {
      readAccess: 'read:broadcast',
      writeAccess: 'write:broadcast',
    },
  },

  {
    id: 3,
    name: 'Chatbot',
    icon: BotMessageSquare,
    link: 'automation',
    global: false,
    isPro: true,
    // amplitudeEventName: 'Clicked on Automation',
    permission: {
      readAccess: 'read:bot_building',
      writeAccess: 'write:bot_building',
    },
  },
  {
    id: 10,
    name: 'Automation',
    icon: Workflow,
    link: 'workflow-automation',
    global: false,
    isPro: true,
    // amplitudeEventName: 'Clicked on Automation',
    permission: {
      readAccess: 'read:bot_building',
      writeAccess: 'write:bot_building',
    },
  },
  {
    id: 2,
    name: 'Integrations',
    icon: LayoutGrid,
    link: 'integrations/integrated',
    global: true,
    isPro: false,
    // amplitudeEventName: 'Clicked on Integrations',
    permission: {
      readAccess: 'read:channel_integration',
      writeAccess: 'write:channel_integration',
    },
  },
  {
    id: 4,
    name: 'Customers',
    icon: Users,
    link: 'customer',
    global: false,
    isPro: false,
    // amplitudeEventName: 'Clicked on Customer',
    permission: {
      readAccess: 'read:audience',
      writeAccess: 'write:audience',
    },
  },
];

export const navMenuEnterprise = [
  {
    id: 6,
    name: 'Datalab',
    icon: DatabaseZap,
    link: 'data-lab',
    global: false,
    isPro: false,
    // amplitudeEventName: 'Clicked on Datalab',
    permission: {
      readAccess: 'read:data_lab',
      writeAccess: 'write:data_lab',
      deleteAccess: 'delete:data_lab',
    },
  },
  {
    id: 7,
    name: 'API',
    icon: Cable,
    link: 'api',
    global: false,
    isPro: true,
    // amplitudeEventName: 'Clicked on API Integration',
    permission: {
      readAccess: 'read:custom_api',
      writeAccess: 'write:custom_api',
    },
  },
];

export const navMenuSettings = [
  {
    id: 8,
    name: 'Settings',
    icon: 'cog',
    link: '/settings/profile',
    linkAccess: '/settings/profile',
    global: true,
    isPro: false,
    // amplitudeEventName: 'Clicked on Settings',
    permission: {
      readAccess: 'read:settings',
      writeAccess: 'write:settings',
    },
  },
];

export const ravenNavMenu = {
  id: 25,
  name: 'Raven',
  icon: BrainCog,
  link: 'raven',
  global: false,
  isPro: false,
  // amplitudeEventName: 'Clicked on raven',
  permission: {
    readAccess: 'read:analytics',
    writeAccess: 'write:analytics',
  },
};

export const navMenuBroadcast = [];

export const postBlockUpdate = (payload) => {
  return payload.map((elem) => {
    switch (elem.type) {
      case 'lab_insert':
        if (!elem.data.lab_id) {
          elem.data.lab_id = !!elem.data.lab?.id ? elem.data.lab?.id : null;
        }
        break;
      case 'product_discovery':
        if (!elem.data.no_products_sequence_id) {
          elem.data.no_products_sequence_id = !!elem.data.no_products_sequence
            ?.id
            ? elem.data.no_products_sequence?.id
            : null;
        }
        break;
      default:
        break;
    }
    return { ...elem, save: true };
  });
};

export const getUpdatedButtonElement = (buttons, data) => {
  const {
    id,
    buttonIndex,
    title,
    type,
    value,
    description,
    messenger_extensions,
    webview_height_ratio,
    form_sequence,
    input_value,
  } = data;
  const newData = {
    id,
    title,
    type,
    value,
    description,
    messenger_extensions,
    webview_height_ratio,
    form_sequence,
    input_value,
  };
  return buttons.map((button, i) => {
    if (buttonIndex === i) {
      return { ...button, ...newData };
    }
    return { ...button };
  });
};

export const getUpdatedBlocks = (blocks, payload, type) => {
  return blocks.map((block) => {
    if (block.id === payload.id) {
      const supportedKeys = ['title', 'subtitle', 'url', 'image'];

      if (type === 'buttons') {
        if (payload.changeKey === 'buttons') {
          block.data.buttons = getUpdatedButtonElement(
            block.data.buttons,
            payload.data
          );
        } else if (payload.changeKey === 'text') {
          block.data[payload.changeKey] = payload.data;
        }
      } else if (type === 'gallery') {
        block.data.elements = block.data.elements.map((elem, i) => {
          if (i === payload.galleryIndex) {
            if (payload.changeKey === 'buttons') {
              const newButtons = getUpdatedButtonElement(
                elem.buttons,
                payload.data
              );
              return { ...elem, buttons: newButtons };
            } else if (supportedKeys.includes(payload.changeKey)) {
              elem[payload.changeKey] = payload.data;
              return { ...elem };
            }
          }
          return { ...elem };
        });
      }
      block.save = false;
    }
    return block;
  });
};

export const handleImageUpload = (file, callback) => {
  if (file?.type === 'image/gif') {
    const formData = new FormData();
    formData.append('file', file, file.name);
    FormDataAxios()
      .post(config.misc.image(), formData)
      .then((res) => {
        callback(res.data.url);
      })
      .catch((err) => {
        console.log(err);
        callback(null);
      });
  } else {
    new Compressor(file, {
      quality: 0.7,
      maxHeight: 1000,
      success(result) {
        const formData = new FormData();
        formData.append('file', result, result.name);
        FormDataAxios()
          .post(config.misc.image(), formData)
          .then((res) => {
            callback(res.data.url);
          })
          .catch((err) => console.log(err));
      },
      error(err) {
        console.log(err.message);
      },
    });
  }
};

export const handleVideoUpload = (file, callback) => {
  if (file?.type.startsWith('video')) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    FormDataAxios()
      .post(config.misc.video(), formData)
      .then((res) => {
        callback(res.data.url);
      })
      .catch((err) => {
        console.log(err);
        callback(null);
      });
  } else {
    new Compressor(file, {
      quality: 0.7,
      maxHeight: 1000,
      success(result) {
        const formData = new FormData();
        formData.append('file', result, result.name);
        FormDataAxios()
          .post(config.misc.video(), formData)
          .then((res) => {
            callback(res.data.url);
          })
          .catch((err) => console.log(err));
      },
      error(err) {
        console.log(err.message);
      },
    });
  }
};

export const handleProductDiscoveryBlockDataUpdate = (blocksLocal, payload) => {
  return blocksLocal.map((block) => {
    if (block.id === payload.blockId) {
      switch (payload.changeKey) {
        case 'input_instructions':
          block.data.input_instructions[payload.indexValue].value =
            payload.payloadData.value;
          break;
        case 'output_instructions':
          block.data.output_instructions[payload.payloadData.name] =
            payload.payloadData.value;
          break;
        case 'no_products_sequence':
          block.data['no_products_sequence_id'] = payload.payloadData.value;
          block.data['no_products_sequence'] = {
            id: payload.payloadData.value,
            title: payload.payloadData.name,
          };
          break;
        default:
          break;
      }
      block.save = false;
    }
    return block;
  });
};

export const handleProductDiscoveryBlockGalleryButtonActions = (
  blocksLocal,
  payload
) => {
  return blocksLocal.map((block) => {
    if (block.id === payload.blockId) {
      switch (payload.actionType) {
        case 'create':
          let buttonCategory = payload.payloadData.buttonCategory;
          block.data.output_instructions.buttons = [
            ...block.data.output_instructions.buttons,
            {
              title:
                buttonCategory === 'ecommerce'
                  ? 'Show Variants'
                  : 'Button Title',
              type:
                buttonCategory === 'ecommerce' ? 'show_variants' : 'sequence',
              category: buttonCategory,
              value: '',
              webview_height_ratio: 'tall',
              messenger_extensions: false,
              form_sequence: 0,
            },
          ];
          break;
        case 'update':
          block.data.output_instructions.buttons = getUpdatedButtonElement(
            block.data.output_instructions.buttons,
            payload.payloadData.buttonData
          );
          break;
        case 'delete':
          block.data.output_instructions.buttons = [
            ...block.data.output_instructions.buttons.slice(
              0,
              payload.payloadData.buttonIndex
            ),
            ...block.data.output_instructions.buttons.slice(
              payload.payloadData.buttonIndex + 1
            ),
          ];
          break;
        case 'changeButtonPosition':
          block.data.output_instructions.buttons =
            payload.payloadData.buttonData;
          break;
        default:
          console.log('');
      }
      block.save = false;
    }
    return block;
  });
};

export const handleImageGalleryUpload = (file, projectId, callback) => {
  if (file?.type === 'image/gif') {
    const formData = new FormData();
    formData.append('file', file, file.name);
    FormDataAxios()
      .post(config.crm.image(projectId), formData)
      .then((res) => {
        if (res.data.success) {
          callback(res.data.dataSource);
        } else {
          callback(null);
        }
      })
      .catch((err) => console.log(err));
  } else {
    new Compressor(file, {
      quality: 0.7,
      maxHeight: 1000,
      success(result) {
        const formData = new FormData();
        formData.append('file', result, result.name);
        FormDataAxios()
          .post(config.crm.image(projectId), formData)
          .then((res) => {
            if (res.data.success) {
              callback(res.data.dataSource);
            } else {
              callback(null);
            }
          })
          .catch((err) => console.log(err));
      },
      error(err) {
        console.log(err.message);
      },
    });
  }
};

export const FormDataAxios = () => {
  const token = !!store ? store.getState()?.auth?.access : '';
  const axiosConfig = Axios.create({
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: !!token ? `Token ${token}` : '',
    },
  });

  return axiosConfig;
};

export const handleFileUpload = (file, callback) => {
  if (!file) {
    return;
  }
  const formData = new FormData();
  formData.append('file', file, file.name);
  FormDataAxios()
    .post(config.misc.file(), formData)
    .then((res) => {
      callback(res.data.url);
    })
    .catch((err) => {
      console.log(err);
      callback(null);
    });
};

export const handleCSVPDFUpload = async (file, callback) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  try {
    const response = await FormDataAxios().post(
      config.misc.fileUpload(),
      formData
    );
    if (response.data.success) {
      callback(response.data.url);
      return { success: true, data: response.data.url };
    }
  } catch (error) {
    return false;
  }
};

export const updateOnChangeText = (
  value,
  setShowAttribute,
  setShowAPI,
  setText
) => {
  let currentValue = value;
  let lastTwoChar = currentValue.slice(
    currentValue.length - 2,
    currentValue.length
  );
  if (lastTwoChar === '{{') {
    setShowAttribute(true);
    setShowAPI(false);
  } else if (lastTwoChar === '<<') {
    !!setShowAPI && setShowAPI(true);
    setShowAttribute(false);
  } else {
    setShowAttribute(false);
    !!setShowAPI && setShowAPI(false);
  }
  setText(value);
};

export const updateOnChangeAttribute = (
  value,
  setShowAttributeField,
  setVariable
) => {
  if (value.length < 3) {
    setShowAttributeField(true);
  } else {
    setShowAttributeField(false);
  }
  setVariable(value);
};

export const updateOnSelectAttribute = (
  intent,
  showAttribute,
  showAPI,
  setShowAttribute,
  setShowAPI,
  setText,
  text,
  apiList
) => {
  let currentValue;
  if (Array.isArray(text)) {
    currentValue = text[text.length - 1];
  } else {
    currentValue = text;
  }
  if (showAttribute) {
    currentValue = currentValue + intent + '}}';
    setShowAttribute(false);
    if (Array.isArray(text)) {
      setText([...text.slice(0, text.length - 1), currentValue]);
    } else {
      setText(currentValue);
    }
  } else {
    let currentAPI = apiList.filter((apis) => apis.title === intent)[0];
    let currentValue = text + currentAPI.title + '|' + currentAPI.id + '>>';
    setShowAPI(false);
    setText(currentValue);
  }
};

export const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getPlatformInfoTitle = (platform) => {
  return (
    platform?.title || platform?.name || getUpdatedPlatformName(platform?.type)
  );
};

export const getUpdatedPlatformName = (type) => {
  switch (type) {
    case 'facebook_messenger':
      return 'Messenger';
    case 'facebook_feed':
      return 'Facebook Feed';
    case 'viber_messenger':
      return 'Viber';
    case 'webchat':
      return 'WebChat';
    case 'livechat_messenger':
      return 'Live Chat';
    case 'app_messenger':
      return 'inApp';
    case 'whatsapp_bsp':
      return 'Whatsapp BSP';
    case 'whatsapp_messenger':
      return 'Whatsapp';
    case 'instagram_messenger':
      return 'Instagram';
    case 'instagram_feed':
      return 'Instagram Feed';
    case 'line_messenger':
      return 'Line';
    case 'telegram_messenger':
      return 'Telegram';
    case 'all':
      return 'ALL Channel';
    default:
      return type;
  }
};

export const getUpdatedPlatformIcon = (type) => {
  switch (type) {
    case 'facebook_messenger':
      return messengerIcon;
    case 'facebook_feed':
      return facebookFeed;
    case 'viber_messenger':
      return viberIcon;
    case 'all':
      return AliceLogo;
    case 'webchat':
      return webchatIcon;
    case 'livechat_messenger':
      return livechatDashboardIcon;
    case 'app_messenger':
      return appIcon;
    case 'whatsapp_bsp':
      return whatappsIcon;
    case 'whatsapp_messenger':
      return whatappsIcon;
    case 'instagram_messenger':
      return instaMessageIcon;
    case 'instagram_feed':
      return instaCommentIcon;
    case 'line_messenger':
      return lineIcon;
    case 'telegram_messenger':
      return telegramIcon;
    case 'telegram_group':
      return telegramGroupIcon;
    default:
      return defaultPlatform;
  }
};

export const getUpdatedPlatformIconOutlined = (type) => {
  switch (type) {
    case 'facebook_messenger':
      return fbMessengerDashboardIcon;
    case 'facebook_feed':
      return fbFeedDashboardIcon;
    case 'viber_messenger':
      return viberDashboardIcon;
    case 'all':
      return AliceLogo;
    case 'webchat':
      return webchatDashboardIcon;
    case 'livechat_messenger':
      return livechatDashboardIcon;
    case 'app_messenger':
      return appDashboardIcon;
    case 'whatsapp_bsp':
      return whatsappDashboardIcon;
    case 'whatsapp_messenger':
      return whatsappDashboardIcon;
    case 'instagram_messenger':
      return instaMessageIcon;
    case 'instagram_feed':
      return instaCommentIcon;
    case 'line_messenger':
      return lineIcon;
    case 'telegram_messenger':
      return telegramIcon;
    case 'telegram_group':
      return telegramGroupIcon;
    case 'custom_email':
    case 'gmail':
    case 'office365':
      return emailIcon;
    default:
      return defaultPlatform;
  }
};

export const validateInputFields = (data, fields) => {
  const requiredFields = fields.filter((f) => f.is_required).map((f) => f.id);
  const dataKeys = Object.keys(data).map((k) => parseInt(k));
  const validated = requiredFields.every((val) => {
    return dataKeys.includes(val);
  });

  const fieldsObj = fields.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: { ...cur },
    }),
    {}
  );

  if (validated) {
    const ignore = [
      'id',
      'lab_id',
      'creator_id',
      'created_at',
      'created_by',
      'last_modified_at',
      'last_modified_by',
      'source',
      'mongo_timestamp',
    ];
    const dataPair = Object.entries(data).filter((d) => !ignore.includes(d[0]));

    const isValid = dataPair.every(([key, value]) => {
      const field = fieldsObj[key];
      if (
        !!field &&
        field.is_required &&
        (value === '' || value === null || value === undefined)
      ) {
        return false;
      }
      return true;
    });

    return isValid;
  }

  return false;
};

export const copyClipboard = async (text) => {
  if (navigator && !!navigator.clipboard && !!window.isSecureContext) {
    await navigator.clipboard.writeText(text);
    return;
  } else {
    let input = document.createElement('input');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    let result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
  }
};

export const isValidPhoneNumber = (text) => {
  const phoneNumberRegex = /^(\+\d{1,3}\s?)?(\d{3,4}[\s.-]?)?\d{7,10}$/;
  return phoneNumberRegex.test(text);
};

export const isValidEmail = (text) => {
  const email =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
  return email.test(text);
};

export const isValidUsername = (text) => {
  const username = /^[a-zA-Z0-9@.\-_]{5,50}$/gi;
  return username.test(text);
};

export const isValidUrl = (text) => {
  const url =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gi;
  if (!!text) {
    return url.test(text);
  }
  return true;
};

export const truncateText = (text, maxLength) =>
  text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

export const generateRandomString = (length) => {
  let result = '';
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const shortenNumber = (num, digits = 1) => {
  const si = [
    { value: 1e18, symbol: 'QT' },
    { value: 1e15, symbol: 'QD' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' },
    { value: 0, symbol: '' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  function divideNum(divider) {
    return (num / (divider || 1)).toFixed(digits);
  }

  let i = si.findIndex(({ value }) => num >= value);
  if (+divideNum(si[i].value) >= 1e3 && si[i - 1]) {
    i -= 1;
  }
  const { value, symbol } = si[i];
  return divideNum(value).replace(rx, '$1') + symbol;
};

export const convertInputType = (value) => {
  switch (value) {
    case 'CharField':
      return 'text';
    case 'TextField':
      return 'text';
    case 'DateTimeField':
      return 'date';
    default:
      return 'text';
  }
};

export const freshChatInit = () => {
  function initFreshChat() {
    window.fcWidget.init({
      config: { headerProperty: { direction: 'rtl' } },
      token: process.env.REACT_APP_FRESH_CHAT_TOKEN,
      host: 'https://wchat.freshchat.com',
    });
  }

  function initialize(i, t) {
    if (i.getElementById(t)) {
      initFreshChat();
    } else {
      let e = i.createElement('script');
      e.id = t;
      e.async = !0;
      e.src = 'https://wchat.freshchat.com/js/widget.js';
      e.onload = initFreshChat;
      i.head.appendChild(e);
    }
  }

  function initiateCall() {
    if (!window.location.pathname.includes('public')) {
      initialize(document, 'freshchat-js-sdk');
    }
  }

  window.addEventListener
    ? window.addEventListener('load', initiateCall, !1)
    : window.attachEvent('load', initiateCall, !1);
};

export const exportCSV = (data, title) => {
  let link = document.createElement('a');
  link.setAttribute(
    'href',
    URL.createObjectURL(new Blob([data], { type: 'text/csv;charset=utf-8;' }))
  );
  link.setAttribute('download', `${title}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportFile = (data, title, format) => {
  try {
    let link = document.createElement('a');
    link.setAttribute('href', URL.createObjectURL(data));
    link.setAttribute('download', `${title}.${format}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (err) {
    console.log(err);
  }
};

export const checkPermission = (permissions, shortCode) => {
  return (
    permissions.filter((permission) => permission.short_code === shortCode)
      .length > 0
  );
};

export const checkInputLength = (labDetails, inputDetails) => {
  for (let i = 0; i < labDetails.length; i++) {
    let entryId = labDetails[i].id;
    if (
      labDetails[i].type === 'text' ||
      labDetails[i].type === 'number' ||
      labDetails[i].type === 'textarea'
    ) {
      if (inputDetails[entryId]) {
        if (inputDetails[entryId].length < labDetails[i].min_length) {
          toaster.danger(
            'Minimum length for' +
              ' ' +
              labDetails[i].name +
              ' is ' +
              labDetails[i].min_length
          );
          return false;
        }
      }
    }
  }
  return true;
};

export const fetchPriorityIcon = (payload) => {
  switch (payload) {
    case 'high':
      return priorityHighIcon;
    case 'medium':
      return priorityMedIcon;
    case 'low':
    default:
      return priorityLowIcon;
  }
};
export const processFeedPostData = (feedData) => {
  let type = null;
  let content = null;
  let contentItem = '';

  if (feedData?.type === 'text') {
    type = feedData.type;
    content = feedData.text;
  } else if (feedData.type === 'attachment') {
    if (feedData?.attachment?.type === 'video') {
      type = feedData?.attachment?.type;
      content = feedData?.text;
      contentItem = feedData?.attachment?.urls[0];
    } else if (feedData?.attachment?.type === 'image') {
      type = feedData?.attachment?.type;
      content = feedData?.text;
      contentItem = feedData?.attachment?.urls;
    } else {
      type = 'text';
      content = feedData?.text || 'attachment type content was sent';
      contentItem = '';
    }
  } else if (feedData.type === 'note') {
    type = feedData.type;
    content = feedData.data.text;
  } else if (feedData.type === 'action') {
    type = feedData?.data?.data?.type;
    content = feedData?.data?.data?.text;
  }
  content = (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a target='blank' className='text-link' href={decoratedHref} key={key}>
          {decoratedText}
        </a>
      )}
    >
      {content}
    </Linkify>
  );
  return { type, content, contentItem };
};
export const processFeedData = (feedData) => {
  let type = null;
  let content = null;
  let image = '';
  if (feedData?.type === 'text') {
    type = feedData.type;
    content = feedData.text;
  } else if (feedData.type === 'attachment') {
    type = feedData?.attachment?.type;
    content = feedData.text;
    image = feedData?.attachment?.urls[0];
  } else if (feedData.type === 'note') {
    type = feedData.type;
    content = feedData.data.text;
  } else if (feedData.type === 'action') {
    type = feedData?.data?.data?.type;
    content = feedData?.data?.data?.text;
  }
  content = (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a target='blank' className='text-link' href={decoratedHref} key={key}>
          {decoratedText}
        </a>
      )}
    >
      {content}
    </Linkify>
  );
  return { type, content, image };
};
export const processReplyData = (feedData) => {
  let type = null;
  let content = null;
  let image = '';
  if (!!feedData) {
    if (feedData?.type === 'text') {
      type = feedData?.type;
      content = feedData?.data?.text;
    } else if (feedData?.type === 'attachment') {
      type = feedData?.data?.sub_type;
      image = feedData?.data?.urls[0];
      content = '';
    } else if (feedData?.type === 'note') {
      type = feedData?.type;
      content = feedData?.data?.text;
    } else if (feedData?.type === 'action') {
      type = 'text';
      content = feedData?.data?.text;
    }
  }
  content = (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a target='blank' className='text-link' href={decoratedHref} key={key}>
          {decoratedText}
        </a>
      )}
    >
      {content}
    </Linkify>
  );
  return { type, content, image };
};
export const processCustomerReplyData = (feedData) => {
  let type = null;
  let content = null;
  let image = '';
  if (feedData.type === 'text') {
    type = feedData.type;
    content = feedData.text;
  } else if (feedData.type === 'attachment') {
    type = feedData.attachment.type;
    image = feedData?.attachment?.urls[0];
    content = feedData?.text || '';
  } else if (feedData.type === 'note') {
    type = feedData.type;
    content = feedData.data.text;
  } else if (feedData.type === 'action') {
    type = 'text';
    content = feedData?.data?.text;
  }
  content = (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a target='blank' className='text-link' href={decoratedHref} key={key}>
          {decoratedText}
        </a>
      )}
    >
      {content}
    </Linkify>
  );
  return { type, content, image };
};

export const procedReplyData = (replies, platform) => {
  let repliesLocal = [];
  replies.forEach((reply) => {
    let replyLocal = {};
    if (reply.source === 'customer') {
      replyLocal = {
        avatar: reply?.customer_info?.avatar || defaultAvatar,
        content: processCustomerReplyData(reply.data).content,
        contentType: processCustomerReplyData(reply.data).type,
        image: processCustomerReplyData(reply.data).image,
        id: reply._id || reply?.pusher_id,
        link: reply?.permalink_url,
        name: reply?.customer_info?.full_name || 'Anonymous User',
        source: reply.source,
        time: `${moment(reply?.timestamp).fromNow()}`,
        // messageType: reply?.type,
        isHighlighted: !!reply?.is_highlighted,
        // status: reply?.status,
        conversation_id: reply.conversation_id,
        showMsgStatus: true,
        repliedPrivately: reply?.type === 'message',
        msgStatus:
          processCustomerReplyData(reply.data).type === 'note'
            ? 'sent'
            : !reply.status
            ? 'pending'
            : !!reply.status
            ? 'sent'
            : 'failed',
      };
      repliesLocal.push(replyLocal);
    } else {
      replyLocal = {
        avatar: platform?.avatar,
        content: processReplyData(reply.data).content,
        contentType: processReplyData(reply.data).type,
        image: processReplyData(reply.data).image,
        id: reply._id || reply?.pusher_id,
        link: reply?.permalink_url,
        name: platform?.name,
        source: reply.source,
        time: `${moment(reply?.timestamp).fromNow()}`,
        // messageType: reply?.type,
        isHighlighted: !!reply?.is_highlighted,
        // status: reply?.status,
        conversation_id: reply.conversation_id,
        repliedBy: reply.source === 'admin' ? reply?.admin_info?.full_name : '',
        showMsgStatus: true,
        repliedPrivately: reply?.type === 'message',
        msgStatus:
          processReplyData(reply.data).type === 'note'
            ? 'sent'
            : !reply.status
            ? 'pending'
            : !!reply.success
            ? 'sent'
            : 'failed',
      };
      repliesLocal.push(replyLocal);
    }
  });
  return repliesLocal;
};

export const copyReferralValue = (platform, value) => {
  let link = '';
  switch (platform.platform_type) {
    case 'facebook_messenger':
      link = 'https://m.me/' + platform.primary_id + '?ref=' + value;
      break;
    case 'viber_messenger':
      link = 'viber://pa?chatURI=' + platform.username + '&context=' + value;
      break;
    case 'webchat':
      link = platform.url + '?ref=' + value;
      break;
    default:
      link = 'https://m.me/' + platform.primary_id + '?ref=' + value;
  }
  let input = document.createElement('input');
  input.setAttribute('value', link);
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);
  toaster.success('Link Copied', { duration: 1 });
};

export const channelInfo = (type) => {
  switch (type) {
    case 'facebook_messenger':
      return {
        title: 'Messenger',
        image: facebookMessengerIconSet,
        color: '#00B2FF',
      };
    case 'facebook_feed':
      return {
        title: 'Facebook Feed',
        image: facebookFeedIconSet,
        color: '#00B2FF',
      };
    case 'webchat':
      return {
        title: 'Websites',
        image: webChatBubbleOvalLeftEllipsisIconSet,
        color: '#10B981',
      };
    case 'livechat_messenger':
      return {
        title: 'Live Chat',
        image: livechatDashboardIcon,
        color: '#10B981',
      };
    case 'app_messenger':
      return { title: 'Apps', image: appMessengerIconSet, color: 'blue' };
    case 'viber_messenger':
      return { title: 'Viber', image: viberMessengerIconSet, color: '#7360F2' };
    case 'whatsapp_bsp':
      return {
        title: 'WhatsApp BSP',
        image: whatsappMessengerIconSet,
        color: '#25D366',
      };
    case 'whatsapp_messenger':
      return {
        title: 'WhatsApp',
        image: whatsappMessengerIconSet,
        color: '#25D366',
      };
    case 'instagram_messenger':
      return {
        title: 'Instagram Messenger',
        image: instagramMessengerIconSet,
        color: '#E4405F',
      };
    case 'instagram_feed':
      return {
        title: 'Instagram Feed',
        image: instagramFeedIconSet,
        color: '#E4405F',
      };
    case 'line_messenger':
      return { title: 'Line', image: lineMessengerIconSet, color: '#00C300' };
    case 'telegram_messenger':
      return {
        title: 'Telegram',
        image: telegramMessengerIconSet,
        color: '#26A5E4',
      };
    case 'telegram_group':
      return {
        title: 'Telegram Group',
        image: telegramGroupIconSet,
        color: '#26A5E4',
      };
    case 'wit.ai':
      return { title: 'Wit', image: witIconSet, color: 'yellow' };
    case 'intnt.ai':
      return { title: 'Intnt', image: intantIconSet, color: 'orange' };
    case 'gmail':
      return { title: 'Gmail', image: emailIconSet, color: 'orange' };
    case 'custom_email':
      return { title: 'Custom Email', image: emailIconSet, color: 'orange' };
    case 'office365':
      return { title: 'Office365', image: emailIconSet, color: 'orange' };
    case 'myalice.ai':
      return {
        title: 'Myalice NLU',
        image: brainFromAiceIconSet,
        color: '#10B981',
      };
    default:
      return { title: 'Not Available', image: AliceLogo, color: '' };
  }
};

export const generateRandomColor = () => {
  let letters = '0123456789ABCDEF'.split('');
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.round(Math.random() * 15)];
  }
  return color;
};

export const getPlatformColor = (type) => {
  switch (type) {
    case 'facebook_messenger':
      return '#0078FF';
    case 'facebook_feed':
      return '#3b5998';
    case 'webchat':
      return '#F46275';
    case 'app_messenger':
      return '#184d47';
    case 'viber_messenger':
      return '#665CAC';
    default:
      return '#000';
  }
};

export const messengerChannels = [
  'facebook_messenger',
  'viber_messenger',
  'webchat',
  'app_messenger',
  'whatsapp_bsp',
  'whatsapp_messenger',
  'instagram_messenger',
  'telegram_messenger',
  'telegram_group',
  'line_messenger',
  'livechat_messenger',
];

export const emailChannels = ['gmail', 'custom_email', 'office365'];

export const feedChannels = ['facebook_feed', 'instagram_feed'];

export const emailChannel = ['gmail', 'custom_email', 'office365'];

export const customEmailChannelTypes = ['custom_email', 'office365'];

export const reportingTab = [
  {
    id: 0,
    name: 'Customer Metrics',
  },
  {
    id: 1,
    name: 'Automation Metrics',
  },
  {
    id: 2,
    name: 'Agent Metrics',
  },
  {
    id: 3,
    name: 'CSAT Reports',
  },
  {
    id: 4,
    name: 'WhatsApp Metrics',
  },
];

export const getGraphForReporting = (value) => {
  switch (value) {
    case 'up':
      return upIcon;
    case 'down':
      return downIcon;
    default:
      return upIcon;
  }
};

export const getReportingVariableNameFromEndpoint = (endpoint) => {
  switch (endpoint) {
    // customer metrics endpoints
    case 'get_new_customer_count':
      return 'userCount';
    case 'get_interactive_customer_count':
      return 'interactiveUserCount';
    case 'get_triggered_message_count/referral':
      return 'referralGrowthData';
    case 'get_triggered_message_count/intent':
      return 'intentGrowthData';
    case 'get_language_metrics':
      return 'languageData';
    case 'get_timezone_metrics':
      return 'timezoneData';
    case 'get_country_metrics':
      return 'countryData';
    case 'conversation_heatmap':
      return 'heatmapData';

    case 'get_gender_metrics':
      return 'genderData';

    case 'get_postback_and_text_message_count':
      return 'postbackVTextData';

    case 'get_triggered_message_list/referral':
      return 'triggeredMsgPostbackReferral';

    case 'get_triggered_message_list/keyword':
      return 'triggeredMsgKeywordIntent';

    case 'get_triggered_message_list/intent':
      return 'triggeredMsgKeywordIntent';

    case 'get_triggered_message_list/postback':
      return 'triggeredMsgPostbackReferral';

    // automation metrics endpoints
    case 'unique-ticket-count':
      return 'uniqueTicketCount';
    case 'get_avg_ticket_per_user': // Not Displayed Now
      return 'avgTicketPerUser';
    case 'get_avg_ticket_per_overall_user':
      return 'avgTicketPerOverallUser';
    case 'get_ticket_created_count':
      return 'ticketCreatedCount';
    case 'get_ticket_resolved_count':
      return 'ticketResolvedCount';
    case 'get-avg-process-first-response-time':
      return 'avgProcessFRT';

    case 'get_textual_message_vs_automation':
      return 'textualVsAutomation';

    case 'get_tag_cloud':
      return 'ticketTagCloud';

    case 'get_ticket_list':
      return 'ticketListTable';

    // agent metrics endpoints
    case 'get_ticket_avg_queue_time':
      return 'averageQueueTime';

    case 'get_agent_average_resolution_time':
      return 'averageResolutionTime';

    case 'get_agent_average_first_response_time':
      return 'averageFirstResponseTime';

    case 'get_agent_average_response_time':
      return 'agentAverageResponseTime';

    case 'get_agent_sales_conversion_ratio':
      return 'averageSalesConversion';

    case 'get_agent_average_online_time':
      return 'averageOnlineTime';

    case 'get-agent-table':
      return 'agentStats';

    default:
      return 'error';
  }
};

export const getSum = (array, key) => {
  if (!array || array.length === 0) {
    return 0;
  } else if (Object.keys(array[0]).includes(key)) {
    const sum = array.reduce(function (sum, current) {
      return sum + (current[key] || 0);
    }, 0);
    return sum;
  } else {
    return 0;
  }
};

export const allPlatformList = [
  { title: 'Messenger', type: 'facebook_messenger', main_type: 'messenger' },
  { title: 'Facebook Feed', type: 'facebook_feed', main_type: 'feed' },
  { title: 'Webchat', type: 'webchat', main_type: 'messenger' },
  { title: 'App', type: 'app_messenger', main_type: 'messenger' },
  { title: 'Viber', type: 'viber_messenger', main_type: 'messenger' },
  { title: 'Whatsapp BSP', type: 'whatsapp_bsp', main_type: 'messenger' },
  { title: 'Whatsapp', type: 'whatsapp_messenger', main_type: 'messenger' },
  { title: 'Instagram Feed', type: 'instagram_feed', main_type: 'feed' },
  { title: 'Instagram', type: 'instagram_messenger', main_type: 'messenger' },
  { title: 'Line', type: 'line_messenger', main_type: 'messenger' },
  { title: 'Telegram', type: 'telegram_messenger', main_type: 'messenger' },
  {
    title: 'Livechat Messenger',
    type: 'livechat_messenger',
    main_type: 'messenger',
  },
  {
    title: 'Email Integration',
    type: 'gmail',
    main_type: 'messenger',
  },
  {
    title: 'Custom Email Integration',
    type: 'custom_email',
    main_type: 'messenger',
  },
  {
    title: 'Office365 Integration',
    type: 'office365',
    main_type: 'messenger',
  },
];

export const getBlockRulesSeedData = (destination) => {
  const pattern = {
    keyword: ['post'],
    intent: ['post'],
    referral: [],
    post: ['keyword', 'intent'],
    url: [],
  };
  let data = null;
  switch (destination) {
    case 'facebook_messenger':
      data = {
        sources: ['facebook_messenger', 'facebook_feed'],
        actions: {
          facebook_messenger: ['keyword', 'intent', 'referral'],
          facebook_feed: ['keyword', 'intent', 'post'],
        },
        pattern,
      };
      return data;
    case 'facebook_feed':
      data = {
        sources: ['facebook_feed'],
        actions: {
          facebook_feed: ['keyword', 'intent', 'post'],
        },
        pattern,
      };
      return data;
    case 'whatsapp_bsp':
      data = {
        sources: ['whatsapp_bsp'],
        actions: {
          whatsapp_bsp: ['keyword', 'intent', 'referral'],
        },
        pattern,
      };
      return data;
    case 'whatsapp_messenger':
      data = {
        sources: ['whatsapp_messenger'],
        actions: {
          whatsapp_messenger: ['keyword', 'intent'],
        },
        pattern,
      };
      return data;
    case 'viber_messenger':
      data = {
        sources: ['viber_messenger'],
        actions: {
          viber_messenger: ['keyword', 'intent', 'referral'],
        },
        pattern,
      };
      return data;
    case 'webchat':
      data = {
        sources: ['webchat'],
        actions: {
          webchat: ['keyword', 'intent', 'referral', 'url'],
        },
        pattern,
      };
      return data;
    case 'app_messenger':
      data = {
        sources: ['app_messenger'],
        actions: {
          app_messenger: ['keyword', 'intent', 'referral'],
        },
        pattern,
      };
      return data;
    case 'instagram_messenger':
      data = {
        sources: ['instagram_messenger'],
        actions: {
          app_messenger: ['keyword', 'intent'],
        },
        pattern,
      };
      return data;
    case 'instagram_feed':
      data = {
        sources: ['instagram_feed'],
        actions: {
          app_messenger: ['keyword', 'intent'],
        },
        pattern,
      };
      return data;
    case 'line_messenger':
      data = {
        sources: ['line_messenger'],
        actions: {
          app_messenger: ['keyword', 'intent'],
        },
        pattern,
      };
      return data;
    case 'telegram_messenger':
      data = {
        sources: ['telegram_messenger'],
        actions: {
          app_messenger: ['keyword', 'intent'],
        },
        pattern,
      };
      return data;
    default:
      return data;
  }
};

export const findAlertInfo = (intent) => {
  switch (intent) {
    case 'success':
      return { icon: successIcon, className: 'text-success' };
    case 'danger':
      return { icon: dangerIcon, className: 'text-danger' };
    case 'warning':
      return { icon: warningIcon, className: 'text-warning' };
    default:
      return { icon: successIcon, className: 'text-success' };
  }
};

export const generateId = (length) => {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const grantNotificationAccess = () => {
  if (window.Notification && Notification.permission === 'default') {
    Notification.requestPermission(() => {
      if (Notification.permission === 'granted') {
        // status is "granted", if accepted by user
        toaster.success('Success', {
          description: 'Successfully Activated Browser Notification',
        });
      }
    });
  }
};

export const initiateNotification = (title, body) => {
  if (window.Notification && Notification.permission === 'granted') {
    const notification = new Notification(title, {
      body: body,
    });
    setTimeout(() => {
      notification.close();
    }, 5000); //5s closing timeout
  } else {
    grantNotificationAccess();
  }
};

export const getIsReadIcon = (value) => {
  switch (value) {
    case true:
      return isReadIcon;
    case false:
      return isUnreadIcon;
    default:
      return isReadIcon;
  }
};

export const getShortNumber = (number) => {
  if (isNaN(number)) return number;

  if (number < 9999) {
    return number;
  }

  if (number < 1000000) {
    return Math.round(number / 1000) + 'K';
  }
  if (number < 10000000) {
    return (number / 1000000).toFixed(2) + 'M';
  }

  if (number < 1000000000) {
    return Math.round(number / 1000000) + 'M';
  }

  if (number < 1000000000000) {
    return Math.round(number / 1000000000) + 'B';
  }

  return '1T+';
};

export const openInSelfTab = (url) => {
  if (!url.startsWith('http')) {
    url = 'https://' + url;
  }
  const newWindow = window.open(url, '_self', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const openInNewTab = (url) => {
  if (!url.startsWith('http')) {
    url = 'https://' + url;
  }
  window.open(url, '_blank');
};

export const useTimer = (timeSpan) => {
  const [timeLeft, setTimeLeft] = React.useState(timeSpan);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return timeLeft;
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const getMauFontSize = (value) => {
  if (typeof value === 'number') {
    switch (value.toString().length) {
      case 1:
      case 2:
      case 3:
      case 4:
        return 32;
      case 5:
        return 28;
      case 6:
        return 24;
      case 7:
        return 20;
      case 8:
        return 16;
      case 10:
        return 14;
      case 9:
      default:
        return 16;
    }
  } else {
    return 36;
  }
};

export const getButtonTypes = (channel, subscriptionPlanType) => {
  switch (channel) {
    case 'whatsapp_messenger':
      return ['Sequence', 'URL'];
    case 'whatsapp_bsp':
      return ['Sequence', 'URL'];
    case 'facebook_messenger':
      return [
        'enterprise',
        'business',
        'myalice_appsumo_premium',
        'appsumo',
      ].includes(subscriptionPlanType)
        ? ['Sequence', 'URL', 'Phone', 'Form']
        : ['Sequence', 'URL', 'Phone'];
    default:
      return [
        'enterprise',
        'business',
        'myalice_appsumo_premium',
        'appsumo',
      ].includes(subscriptionPlanType)
        ? ['Sequence', 'URL', 'Form']
        : ['Sequence', 'URL'];
  }
};

export const countryData = [
  {
    name: 'Afghanistan',
    code: 'AF',
  },
  {
    name: 'Åland Islands',
    code: 'AX',
  },
  {
    name: 'Albania',
    code: 'AL',
  },
  {
    name: 'Algeria',
    code: 'DZ',
  },
  {
    name: 'American Samoa',
    code: 'AS',
  },
  {
    name: 'AndorrA',
    code: 'AD',
  },
  {
    name: 'Angola',
    code: 'AO',
  },
  {
    name: 'Anguilla',
    code: 'AI',
  },
  {
    name: 'Antarctica',
    code: 'AQ',
  },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
  },
  {
    name: 'Argentina',
    code: 'AR',
  },
  {
    name: 'Armenia',
    code: 'AM',
  },
  {
    name: 'Aruba',
    code: 'AW',
  },
  {
    name: 'Australia',
    code: 'AU',
  },
  {
    name: 'Austria',
    code: 'AT',
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
  },
  {
    name: 'Bahamas',
    code: 'BS',
  },
  {
    name: 'Bahrain',
    code: 'BH',
  },
  {
    name: 'Bangladesh',
    code: 'BD',
  },
  {
    name: 'Barbados',
    code: 'BB',
  },
  {
    name: 'Belarus',
    code: 'BY',
  },
  {
    name: 'Belgium',
    code: 'BE',
  },
  {
    name: 'Belize',
    code: 'BZ',
  },
  {
    name: 'Benin',
    code: 'BJ',
  },
  {
    name: 'Bermuda',
    code: 'BM',
  },
  {
    name: 'Bhutan',
    code: 'BT',
  },
  {
    name: 'Bolivia',
    code: 'BO',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BA',
  },
  {
    name: 'Botswana',
    code: 'BW',
  },
  {
    name: 'Bouvet Island',
    code: 'BV',
  },
  {
    name: 'Brazil',
    code: 'BR',
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
  },
  {
    name: 'Brunei Darussalam',
    code: 'BN',
  },
  {
    name: 'Bulgaria',
    code: 'BG',
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
  },
  {
    name: 'Burundi',
    code: 'BI',
  },
  {
    name: 'Cambodia',
    code: 'KH',
  },
  {
    name: 'Cameroon',
    code: 'CM',
  },
  {
    name: 'Canada',
    code: 'CA',
  },
  {
    name: 'Cape Verde',
    code: 'CV',
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
  },
  {
    name: 'Central African Republic',
    code: 'CF',
  },
  {
    name: 'Chad',
    code: 'TD',
  },
  {
    name: 'Chile',
    code: 'CL',
  },
  {
    name: 'China',
    code: 'CN',
  },
  {
    name: 'Christmas Island',
    code: 'CX',
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
  },
  {
    name: 'Colombia',
    code: 'CO',
  },
  {
    name: 'Comoros',
    code: 'KM',
  },
  {
    name: 'Congo',
    code: 'CG',
  },
  {
    name: 'Congo, The Democratic Republic of the',
    code: 'CD',
  },
  {
    name: 'Cook Islands',
    code: 'CK',
  },
  {
    name: 'Costa Rica',
    code: 'CR',
  },
  {
    name: 'Cote D"Ivoire',
    code: 'CI',
  },
  {
    name: 'Croatia',
    code: 'HR',
  },
  {
    name: 'Cuba',
    code: 'CU',
  },
  {
    name: 'Cyprus',
    code: 'CY',
  },
  {
    name: 'Czech Republic',
    code: 'CZ',
  },
  {
    name: 'Denmark',
    code: 'DK',
  },
  {
    name: 'Djibouti',
    code: 'DJ',
  },
  {
    name: 'Dominica',
    code: 'DM',
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
  },
  {
    name: 'Ecuador',
    code: 'EC',
  },
  {
    name: 'Egypt',
    code: 'EG',
  },
  {
    name: 'El Salvador',
    code: 'SV',
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
  },
  {
    name: 'Eritrea',
    code: 'ER',
  },
  {
    name: 'Estonia',
    code: 'EE',
  },
  {
    name: 'Ethiopia',
    code: 'ET',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    code: 'FK',
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
  },
  {
    name: 'Fiji',
    code: 'FJ',
  },
  {
    name: 'Finland',
    code: 'FI',
  },
  {
    name: 'France',
    code: 'FR',
  },
  {
    name: 'French Guiana',
    code: 'GF',
  },
  {
    name: 'French Polynesia',
    code: 'PF',
  },
  {
    name: 'French Southern Territories',
    code: 'TF',
  },
  {
    name: 'Gabon',
    code: 'GA',
  },
  {
    name: 'Gambia',
    code: 'GM',
  },
  {
    name: 'Georgia',
    code: 'GE',
  },
  {
    name: 'Germany',
    code: 'DE',
  },
  {
    name: 'Ghana',
    code: 'GH',
  },
  {
    name: 'Gibraltar',
    code: 'GI',
  },
  {
    name: 'Greece',
    code: 'GR',
  },
  {
    name: 'Greenland',
    code: 'GL',
  },
  {
    name: 'Grenada',
    code: 'GD',
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
  },
  {
    name: 'Guam',
    code: 'GU',
  },
  {
    name: 'Guatemala',
    code: 'GT',
  },
  {
    name: 'Guernsey',
    code: 'GG',
  },
  {
    name: 'Guinea',
    code: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    code: 'GW',
  },
  {
    name: 'Guyana',
    code: 'GY',
  },
  {
    name: 'Haiti',
    code: 'HT',
  },
  {
    name: 'Heard Island and Mcdonald Islands',
    code: 'HM',
  },
  {
    name: 'Holy See (Vatican City State)',
    code: 'VA',
  },
  {
    name: 'Honduras',
    code: 'HN',
  },
  {
    name: 'Hong Kong',
    code: 'HK',
  },
  {
    name: 'Hungary',
    code: 'HU',
  },
  {
    name: 'Iceland',
    code: 'IS',
  },
  {
    name: 'India',
    code: 'IN',
  },
  {
    name: 'Indonesia',
    code: 'ID',
  },
  {
    name: 'Iran, Islamic Republic Of',
    code: 'IR',
  },
  {
    name: 'Iraq',
    code: 'IQ',
  },
  {
    name: 'Ireland',
    code: 'IE',
  },
  {
    name: 'Isle of Man',
    code: 'IM',
  },
  {
    name: 'Israel',
    code: 'IL',
  },
  {
    name: 'Italy',
    code: 'IT',
  },
  {
    name: 'Jamaica',
    code: 'JM',
  },
  {
    name: 'Japan',
    code: 'JP',
  },
  {
    name: 'Jersey',
    code: 'JE',
  },
  {
    name: 'Jordan',
    code: 'JO',
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
  },
  {
    name: 'Kenya',
    code: 'KE',
  },
  {
    name: 'Kiribati',
    code: 'KI',
  },
  {
    name: 'Korea, Democratic People"S Republic of',
    code: 'KP',
  },
  {
    name: 'Korea, Republic of',
    code: 'KR',
  },
  {
    name: 'Kuwait',
    code: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
  },
  {
    name: 'Lao People"S Democratic Republic',
    code: 'LA',
  },
  {
    name: 'Latvia',
    code: 'LV',
  },
  {
    name: 'Lebanon',
    code: 'LB',
  },
  {
    name: 'Lesotho',
    code: 'LS',
  },
  {
    name: 'Liberia',
    code: 'LR',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    code: 'LY',
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
  },
  {
    name: 'Lithuania',
    code: 'LT',
  },
  {
    name: 'Luxembourg',
    code: 'LU',
  },
  {
    name: 'Macao',
    code: 'MO',
  },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    code: 'MK',
  },
  {
    name: 'Madagascar',
    code: 'MG',
  },
  {
    name: 'Malawi',
    code: 'MW',
  },
  {
    name: 'Malaysia',
    code: 'MY',
  },
  {
    name: 'Maldives',
    code: 'MV',
  },
  {
    name: 'Mali',
    code: 'ML',
  },
  {
    name: 'Malta',
    code: 'MT',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
  },
  {
    name: 'Martinique',
    code: 'MQ',
  },
  {
    name: 'Mauritania',
    code: 'MR',
  },
  {
    name: 'Mauritius',
    code: 'MU',
  },
  {
    name: 'Mayotte',
    code: 'YT',
  },
  {
    name: 'Mexico',
    code: 'MX',
  },
  {
    name: 'Micronesia, Federated States of',
    code: 'FM',
  },
  {
    name: 'Moldova, Republic of',
    code: 'MD',
  },
  {
    name: 'Monaco',
    code: 'MC',
  },
  {
    name: 'Mongolia',
    code: 'MN',
  },
  {
    name: 'Montserrat',
    code: 'MS',
  },
  {
    name: 'Morocco',
    code: 'MA',
  },
  {
    name: 'Mozambique',
    code: 'MZ',
  },
  {
    name: 'Myanmar',
    code: 'MM',
  },
  {
    name: 'Namibia',
    code: 'NA',
  },
  {
    name: 'Nauru',
    code: 'NR',
  },
  {
    name: 'Nepal',
    code: 'NP',
  },
  {
    name: 'Netherlands',
    code: 'NL',
  },
  {
    name: 'Netherlands Antilles',
    code: 'AN',
  },
  {
    name: 'New Caledonia',
    code: 'NC',
  },
  {
    name: 'New Zealand',
    code: 'NZ',
  },
  {
    name: 'Nicaragua',
    code: 'NI',
  },
  {
    name: 'Niger',
    code: 'NE',
  },
  {
    name: 'Nigeria',
    code: 'NG',
  },
  {
    name: 'Niue',
    code: 'NU',
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    name: 'Norway',
    code: 'NO',
  },
  {
    name: 'Oman',
    code: 'OM',
  },
  {
    name: 'Pakistan',
    code: 'PK',
  },
  {
    name: 'Palau',
    code: 'PW',
  },
  {
    name: 'Palestinian Territory, Occupied',
    code: 'PS',
  },
  {
    name: 'Panama',
    code: 'PA',
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
  },
  {
    name: 'Paraguay',
    code: 'PY',
  },
  {
    name: 'Peru',
    code: 'PE',
  },
  {
    name: 'Philippines',
    code: 'PH',
  },
  {
    name: 'Pitcairn',
    code: 'PN',
  },
  {
    name: 'Poland',
    code: 'PL',
  },
  {
    name: 'Portugal',
    code: 'PT',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
  },
  {
    name: 'Qatar',
    code: 'QA',
  },
  {
    name: 'Reunion',
    code: 'RE',
  },
  {
    name: 'Romania',
    code: 'RO',
  },
  {
    name: 'Russian Federation',
    code: 'RU',
  },
  {
    name: 'RWANDA',
    code: 'RW',
  },
  {
    name: 'Saint Helena',
    code: 'SH',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
  },
  {
    name: 'Samoa',
    code: 'WS',
  },
  {
    name: 'San Marino',
    code: 'SM',
  },
  {
    name: 'Sao Tome and Principe',
    code: 'ST',
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
  },
  {
    name: 'Senegal',
    code: 'SN',
  },
  {
    name: 'Serbia and Montenegro',
    code: 'CS',
  },
  {
    name: 'Seychelles',
    code: 'SC',
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
  },
  {
    name: 'Singapore',
    code: 'SG',
  },
  {
    name: 'Slovakia',
    code: 'SK',
  },
  {
    name: 'Slovenia',
    code: 'SI',
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
  },
  {
    name: 'Somalia',
    code: 'SO',
  },
  {
    name: 'South Africa',
    code: 'ZA',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
  },
  {
    name: 'Spain',
    code: 'ES',
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
  },
  {
    name: 'Sudan',
    code: 'SD',
  },
  {
    name: 'Suriname',
    code: 'SR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: 'SJ',
  },
  {
    name: 'Swaziland',
    code: 'SZ',
  },
  {
    name: 'Sweden',
    code: 'SE',
  },
  {
    name: 'Switzerland',
    code: 'CH',
  },
  {
    name: 'Syrian Arab Republic',
    code: 'SY',
  },
  {
    name: 'Taiwan, Province of China',
    code: 'TW',
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
  },
  {
    name: 'Tanzania, United Republic of',
    code: 'TZ',
  },
  {
    name: 'Thailand',
    code: 'TH',
  },
  {
    name: 'Timor-Leste',
    code: 'TL',
  },
  {
    name: 'Togo',
    code: 'TG',
  },
  {
    name: 'Tokelau',
    code: 'TK',
  },
  {
    name: 'Tonga',
    code: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
  },
  {
    name: 'Tunisia',
    code: 'TN',
  },
  {
    name: 'Turkey',
    code: 'TR',
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
  },
  {
    name: 'Tuvalu',
    code: 'TV',
  },
  {
    name: 'Uganda',
    code: 'UG',
  },
  {
    name: 'Ukraine',
    code: 'UA',
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
  },
  {
    name: 'United Kingdom',
    code: 'GB',
  },
  {
    name: 'United States',
    code: 'US',
  },
  {
    name: 'United States Minor Outlying Islands',
    code: 'UM',
  },
  {
    name: 'Uruguay',
    code: 'UY',
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
  },
  {
    name: 'Vanuatu',
    code: 'VU',
  },
  {
    name: 'Venezuela',
    code: 'VE',
  },
  {
    name: 'Viet Nam',
    code: 'VN',
  },
  {
    name: 'Virgin Islands, British',
    code: 'VG',
  },
  {
    name: 'Virgin Islands, U.S.',
    code: 'VI',
  },
  {
    name: 'Wallis and Futuna',
    code: 'WF',
  },
  {
    name: 'Western Sahara',
    code: 'EH',
  },
  {
    name: 'Yemen',
    code: 'YE',
  },
  {
    name: 'Zambia',
    code: 'ZM',
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
  },
];

export const LinearLoaderComponent = () => {
  return (
    <div className='min-w-full border-b border-l divide-y divide-gray-200 shadow'>
      <div className='px-6 py-4 bg-white animate-pulse'>
        {/*10 row loader*/}
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
          <div
            key={item}
            className={`h-4 bg-gray-200 mb-6 ${
              item === 0 ? 'mt-3' : ''
            } rounded`}
          />
        ))}
      </div>
    </div>
  );
};

export const getPlatformsGalleryButtonMaxNumber = (platformType) => {
  switch (platformType) {
    case 'facebook_messenger':
    case 'webchat':
    case 'app_messenger':
    case 'line_messenger':
      return 10;
    case 'viber_messenger':
      return 8;
    default:
      return 1;
  }
};

export const getQueryParameters = (key) => {
  let url = new URL(window.location.href);
  return url.searchParams.get(key) ?? '';
};

export const getQueryToken = (key) => {
  return new URLSearchParams(window.location.search).get(key, '') || '';
};

export const isObjectEmpty = (object) => {
  let isEmpty = true;
  if (Object.keys(object).length > 0) {
    isEmpty = false;
  }
  return isEmpty;
};

export const getALLChannelData = [
  {
    id: 0,
    name: 'Facebook Messenger',
    platform_type: 'facebook_messenger',
    icon: facebookMessengerIconSet,
  },
  {
    id: 1,
    name: 'Facebook Feed',
    platform_type: 'facebook_feed',
    icon: facebookFeedIconSet,
  },
  {
    id: 2,
    name: 'Viber Messenger',
    platform_type: 'viber_messenger',
    icon: viberMessengerIconSet,
  },
  // {
  //   id: 3,
  //   name: 'Website Plugin',
  //   platform_type: 'webchat',
  //   icon: webChatBubbleOvalLeftEllipsisIconSet,
  // },
  {
    id: 4,
    name: 'Mobile App Plugin',
    platform_type: 'app_messenger',
    icon: appMessengerIconSet,
  },
  {
    id: 5,
    name: 'Whatsapp BSP',
    platform_type: 'whatsapp_bsp',
    icon: whatsappMessengerIconSet,
  },
  {
    id: 6,
    name: 'Whatsapp Messenger',
    platform_type: 'whatsapp_messenger',
    icon: whatsappMessengerIconSet,
  },
  {
    id: 7,
    name: 'Instagram Messenger',
    platform_type: 'instagram_messenger',
    icon: instagramMessengerIconSet,
  },
  {
    id: 8,
    name: 'Instagram Feed',
    platform_type: 'instagram_feed',
    icon: instagramFeedIconSet,
  },
  {
    id: 9,
    name: 'Line Messenger',
    platform_type: 'line_messenger',
    icon: lineMessengerIconSet,
  },
  {
    id: 10,
    name: 'Telegram Messenger',
    platform_type: 'telegram_messenger',
    icon: telegramMessengerIconSet,
  },
  {
    id: 11,
    name: 'Live Chat Plugin',
    platform_type: 'livechat_messenger',
    icon: livechatDashboardIcon,
  },
  // add new icons for new platform/ channel integration
];

export const getAllMarketPlaceData = [
  {
    id: 0,
    name: 'Shopify',
    platform_type: 'shopify',
    icon: shopifyIconSet,
  },
  {
    id: 1,
    name: 'WooCommerce',
    platform_type: 'woocommerce',
    icon: wooCommerceIconSet,
  },
  {
    id: 2,
    name: 'Salla',
    platform_type: 'salla',
    icon: sallaIconSet,
  },
  {
    id: 3,
    name: 'Zid',
    platform_type: 'zid',
    icon: zidIconSet,
  },

  // add new icons for new marketplace integration
];

export const getChannelInfo = (data, type) => {
  const channel = data.filter((channel) => channel.platform_type === type);
  if (channel.length > 0) {
    return channel[0];
  } else {
    return null;
  }
};

export const onboardPermission = [
  'basic_info',
  'business_info',
  'create_channels',
];
export const onboardDialogPermission = [
  'invite_team',
  'build_chatbot',
  'connect_channels',
  'connect_ecommerce',
];

export const getInitials = (name) => {
  const hasTokens = name.indexOf(' ') !== -1;
  return (
    name.substring(0, hasTokens ? 1 : 2) +
    (hasTokens ? name.charAt(name.lastIndexOf(' ') + 1) : '')
  );
};

export const initialFilterData = {
  channels: [],
  startDate: '',
  endDate: '',
  isResolved: 0,
  tags: [],
  agents: [],
  groups: [],
  agentSelection: 'all',
  groupSelection: 'all',
};

const initializeMixpanel = () => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
    track_pageview: true,
  });
  return mixpanel;
};

export const mixpanelAnalytics = initializeMixpanel();

export const DEFAULT_PLATFORM_ATTRIBUTE_LIST = [
  'full_name',
  'first_name',
  'last_name',
  'phone',
  'email',
  'address',
  'gender',
  'timezone',
  'locale',
  'primary_id',
  'language',
  'last_message_text',
  'last_message_time',
  'created_at',
];

export const monthList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const yearList = range(1990, getYear(new Date()) + 1, 1);

export const getIsoDatetimeString = (date, timeOffset) => {
  if (date.length !== 0) {
    let cleanedOffset = timeOffset;
    let rawOffsetData = timeOffset.split(':');
    if (rawOffsetData[0].length < 3) {
      let hour = rawOffsetData[0].split('');
      cleanedOffset = `${hour[0]}0${hour[1]}:${rawOffsetData[1]}`;
    }
    let isoFormattedDate = !!cleanedOffset
      ? moment(date).format(`YYYY-MM-DDTHH:mm:ss${cleanedOffset}`)
      : null;
    return !!isoFormattedDate ? isoFormattedDate : '';
  } else return '';
};
export const daysOfWeek = [
  {
    id: 1,
    name: 'Sunday',
    symbol: 'S',
  },
  {
    id: 2,
    name: 'Monday',
    symbol: 'M',
  },
  {
    id: 3,
    name: 'Tuesday',
    symbol: 'T',
  },
  {
    id: 4,
    name: 'Wednesday',
    symbol: 'W',
  },
  {
    id: 5,
    name: 'Thursday',
    symbol: 'T',
  },
  {
    id: 6,
    name: 'Friday',
    symbol: 'F',
  },
  {
    id: 7,
    name: 'Saturday',
    symbol: 'S',
  },
];

export const platformIconWithColor = (
  type,
  color = '',
  secondaryColor = 'white',
  classNames = 'w-4 h-4 hover:white'
) => {
  switch (type) {
    case 'facebook_messenger':
      return (
        <FacebookMessengerIconWithColor color={color} className={classNames} />
      );
    case 'facebook_feed':
      return <FacebookIconWithColor color={color} className={classNames} />;
    case 'viber_messenger':
      return <ViberIconWithColor color={color} className={classNames} />;
    case 'all':
      return <DefaultIconWithColor color={color} className={classNames} />;
    case 'webchat':
      return (
        <WebChatBubbleOvalLeftEllipsisIconWithColor
          color={color}
          className={classNames}
        />
      );
    case 'livechat_messenger':
      return (
        <WebChatBubbleOvalLeftEllipsisIconWithColor
          color={color}
          className={classNames}
        />
      );
    case 'app_messenger':
      return <AppMessengerIconWithColor color={color} className={classNames} />;
    case 'whatsapp_bsp':
      return <WhatsappIconWithColor color={color} className={classNames} />;
    case 'whatsapp_messenger':
      return <WhatsappIconWithColor color={color} className={classNames} />;
    case 'instagram_messenger':
      return (
        <InstagramMessengerIconWithColor
          color={color}
          secondaryColor={secondaryColor}
          className={classNames}
        />
      );
    case 'instagram_feed':
      return <InstagramIconWithColor color={color} className={classNames} />;
    case 'line_messenger':
      return <LineIconWithColor color={color} className={classNames} />;
    case 'telegram_messenger':
      return <TelegramIconWithColor color={color} className={classNames} />;
    default:
      return <DefaultIconWithColor color={color} className={classNames} />;
  }
};

export const NotificationSound = new Audio(
  'https://live-alice-v3.s3-ap-southeast-1.amazonaws.com/create_ticket.mp3'
);

export const CircledChannelIcons = ({
  type,
  color = '',
  hasHoverEffect = false,
  isHovered = false,
  hoverColor = '#9DA8BB',
  className = '',
  height = '32',
  width = '32',
}) => {
  switch (type) {
    case 'facebook_messenger':
      return (
        <CircledFaceBookMessengerIcon
          fillColor={hasHoverEffect && isHovered ? hoverColor : color}
          classNames={className}
          height={height}
          width={width}
        />
      );
    case 'facebook_feed':
      return (
        <CircledFaceBookIcon
          fillColor={hasHoverEffect && isHovered ? hoverColor : color}
          classNames={className}
          height={height}
          width={width}
        />
      );
    case 'viber_messenger':
      return (
        <CircledViberIcon
          fillColor={hasHoverEffect && isHovered ? hoverColor : color}
          classNames={className}
          height={height}
          width={width}
        />
      );
    case 'instagram_feed':
      return (
        <CircledInstagramFeedIcon
          fillColor={hasHoverEffect && isHovered ? hoverColor : color}
          classNames={className}
          height={height}
          width={width}
        />
      );
    case 'instagram_messenger':
      return (
        <CircledInstagramMessengerIcon
          fillColor={hasHoverEffect && isHovered ? hoverColor : color}
          classNames={className}
          height={height}
          width={width}
        />
      );
    case 'line_messenger':
      return (
        <CircledLineIcon
          fillColor={hasHoverEffect && isHovered ? hoverColor : color}
          classNames={className}
          height={height}
          width={width}
        />
      );
    case 'telegram_messenger':
      return (
        <CircledTelegramIcon
          fillColor={hasHoverEffect && isHovered ? hoverColor : color}
          classNames={className}
          height={height}
          width={width}
        />
      );
    case 'telegram_group':
      return (
        <CircledTelegramGroupIcon
          fillColor={hasHoverEffect && isHovered ? hoverColor : color}
          height={height}
          classNames={cn(classNames, 'rounded-[16px]')}
          width={width}
        />
      );
    case 'livechat_messenger':
      return (
        <CircledLiveChatBubbleOvalLeftEllipsisIcon
          fillColor={hasHoverEffect && isHovered ? hoverColor : color}
          classNames={className}
          height={height}
          width={width}
        />
      );
    case 'whatsapp_messenger':
      return (
        <CircledWhatsappIcon
          fillColor={hasHoverEffect && isHovered ? hoverColor : color}
          classNames={className}
          height={height}
          width={width}
        />
      );
    case 'whatsapp_bsp':
      return (
        <CircledWhatsappBspIcon
          fillColor={hasHoverEffect && isHovered ? hoverColor : color}
          classNames={cn(classNames, 'rounded-[16px]')}
          height={height}
          width={width}
        />
      );
    case 'app_messenger':
      return (
        <CircledMobileIcon
          fillColor={hasHoverEffect && isHovered ? hoverColor : color}
          classNames={className}
          height={height}
          width={width}
        />
      );
    case 'custom_email':
    case 'office365':
    case 'gmail':
      return (
        <CircledEmailIcon
          fillColor={hasHoverEffect && isHovered ? hoverColor : color}
          classNames={className}
          height={height}
          width={width}
        />
      );
    default:
      return (
        <CircledMobileIcon
          fillColor={hasHoverEffect && isHovered ? hoverColor : color}
          classNames={className}
          height={height}
          width={width}
        />
      );
  }
};

/**
 * document.exec is depcricated and navigator.clipboard is highly suggested
 * link for study: https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
 * @param text: string
 */

export const CopyToClipboard = (text) => {
  return new Promise((resolve, reject) => {
    if (
      typeof navigator !== 'undefined' &&
      typeof navigator.clipboard !== 'undefined' &&
      navigator.permissions !== 'undefined'
    ) {
      navigator.permissions
        .query({ name: 'clipboard-write' })
        .then((permission) => {
          if (permission.state === 'granted' || permission.state === 'prompt') {
            navigator.clipboard
              .writeText(text)
              .then(resolve, reject)
              .catch((err) => {
                console.log(err);
                reject(new Error("Can't Copy"));
              });
          } else {
            reject(new Error('Permission not granted!'));
          }
        });
    } else if (
      document.queryCommandSupported &&
      document.queryCommandSupported('copy')
    ) {
      var textarea = document.createElement('textarea');
      textarea.textContent = text;
      textarea.style.display = 'none';
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
        document.execCommand('copy');
        document.body.removeChild(textarea);
        resolve();
      } catch (e) {
        document.body.removeChild(textarea);
        reject(e);
      }
    } else {
      reject(
        new Error('None of copying methods are supported by this browser!')
      );
    }
  });
};

export const languageData = [
  {
    id: 0,
    value: 'arabic',
    name: 'عربي',
  },
  {
    id: 1,
    value: 'portuguese',
    name: 'Português',
  },
  {
    id: 2,
    value: 'spanish',
    name: 'Español',
  },
  {
    id: 3,
    value: 'english',
    name: 'US English',
  },
];

export const UtimeForTeamTimeZone = (timeStamp, selectedProject) => {
  if (dayjs(parseInt(timeStamp)).isValid()) {
    let offsetArray = !!selectedProject?.timezone_offset
      ? selectedProject?.timezone_offset?.split(':').map((item) => Number(item))
      : [0, 0];
    let teamOffset = 60 * (offsetArray[0] + offsetArray[1] / 60);
    dayjs.extend(utc);

    return dayjs(parseInt(timeStamp) + teamOffset * 60000)
      .utc()
      .format('DD MMM YYYY, hh:mm A');
  } else {
    return '- -';
  }
};

const compareObjects = (obj1, obj2) => {
  // Check if both inputs are objects
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate over the keys
  for (let key of keys1) {
    // Check if the current key exists in both objects
    if (!(key in obj2)) {
      return false;
    }

    // Get the values of the current key
    const value1 = obj1[key];
    const value2 = obj2[key];

    // Recursively compare nested objects
    if (typeof value1 === 'object' && typeof value2 === 'object') {
      if (!compareObjects(value1, value2)) {
        return false;
      }
    } else {
      // Compare non-object values
      if (value1 !== value2) {
        return false;
      }
    }
  }

  // All checks passed, objects have the same data properties
  return true;
};

// checking if the values are equal for any kind of type
export const checkingEquality = (value1, value2) => {
  if (typeof value1 !== 'object' || typeof value2 !== 'object') {
    return value1 === value2;
  }

  return compareObjects(value1, value2);
};

export const get_avg_ticket_per_overall_user = (
  createdTickets,
  uniqueTickets
) => {
  if (!!createdTickets && !!uniqueTickets) {
    const currentValue = (
      createdTickets.current_value / uniqueTickets.current_value
    ).toFixed(2);
    const previousValue = (
      createdTickets.previous_value / uniqueTickets.previous_value
    ).toFixed(2);
    const growth = currentValue - previousValue;
    const growthValue = ((Math.abs(growth) / previousValue) * 100).toFixed(2);
    return {
      current_value: currentValue,
      growth_direction: growth > 0 ? '+' : '-',
      growth_value: `${growthValue}%`,
      previous_value: previousValue,
      span: 31,
    };
  }
};

export const getMetaPermissionScope = (platform_type) => {
  switch (platform_type) {
    case 'instagram_messenger':
    case 'instagram_feed':
      return 'pages_show_list,pages_messaging,public_profile,pages_read_engagement,pages_read_user_content,pages_manage_engagement,pages_manage_metadata,instagram_basic,instagram_manage_comments,business_management,instagram_manage_messages';
    case 'facebook_messenger':
    case 'facebook_feed':
      return 'pages_show_list,pages_messaging,public_profile,pages_read_engagement,pages_read_user_content,pages_manage_engagement,pages_manage_metadata,business_management';
    default:
      return 'pages_show_list,pages_messaging,public_profile,pages_read_engagement,pages_read_user_content,pages_manage_engagement,pages_manage_metadata,business_management';
  }
};

export const getCssForReadAccessOnly = (active, dataReadAccessOnly) => {
  if (dataReadAccessOnly) return 'text-gray-200  cursor-not-allowed';
  else {
    return `cursor-pointer ${
      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
    }`;
  }
};

const defaultDateFormat = {
  future: 'in %s',
  past: '%s ago',
  s: 'Just now',
  m: '1 minute ago',
  mm: '%d minutes ago',
  h: '1 hour ago',
  hh: '%d hours ago',
  d: '1 day ago',
  dd: '%d days ago',
  M: '1 month ago',
  MM: '%d months ago',
  y: '1 year ago',
  yy: '%d years ago',
};

const threshold = [
  { l: 's', r: 1 },
  { l: 'm', r: 1 },
  { l: 'mm', r: 59, d: 'minute' },
  { l: 'h', r: 1 },
  { l: 'hh', r: 23, d: 'hour' },
  { l: 'd', r: 1 },
  { l: 'dd', r: 29, d: 'day' },
  { l: 'M', r: 1 },
  { l: 'MM', r: 11, d: 'month' },
  { l: 'y' },
  { l: 'yy', d: 'year' },
];

export const getDayJSFormat = (date, formatRef = defaultDateFormat) => {
  if (!isNaN(Number(date)) && date.toString().length < 13) {
    date = Number(date) * 1000;
  }
  const dayJs = dayjs;
  delete relativeTime?.$i; // this allows plugin to be installed dynamically again
  dayJs.extend(relativeTime, {
    rounding: Math.floor,
    thresholds: threshold,
  });
  dayJs.extend(updateLocale);

  dayJs.updateLocale('en', {
    relativeTime: { ...formatRef },
  });
  return dayJs(date).fromNow(true);
};

export const LanguageWithFlag = [
  {
    name: 'عربي',
    value: 'arabic',
    flag: <SaudiArabicIcon />,
  },
  {
    name: 'Português',
    value: 'portuguese',
    flag: <PortugalIcon />,
  },
  {
    name: 'Español',
    value: 'spanish',
    flag: <SpanishIcon />,
  },
  {
    name: 'US English',
    value: 'english',
    flag: <EnglandIcon />,
  },
];

// Function to format time and date and add them
export const CombineDateAndTime = (date, time) => {
  let currentTime;

  if (time instanceof Date) {
    currentTime = time;
  } else {
    // Split time into hours, minutes, and AM/PM
    const [timeStr, ampm] = time.split(' ');
    const [hours, minutes] = timeStr.split(':').map(Number);

    // Adjust hours for AM/PM format
    let adjustedHours = hours;
    if (ampm.toLowerCase() === 'pm' && hours !== 12) {
      adjustedHours += 12;
    } else if (ampm.toLowerCase() === 'am' && hours === 12) {
      adjustedHours = 0;
    }

    // Get current date and time
    currentTime = new Date(2000, 0, 1, adjustedHours, minutes); // Month is 0-indexed in JavaScript
  }

  const currentDate = new Date(date);

  // Add time to date
  const combinedDateTime = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    currentTime.getHours(),
    currentTime.getMinutes(),
    currentTime.getSeconds()
  );

  return combinedDateTime;
};

export const isSameDate = (date1, date2) => {
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();
  const day2 = date2.getDate();

  return year1 === year2 && month1 === month2 && day1 === day2;
};

export const AdjustEndDateTimeRange = (selectedDate) => {
  let adjustTime = new Date(selectedDate);
  const currentDate = new Date();
  const isEndDateBeforeCurrentDate = adjustTime < currentDate;
  if (isSameDate(adjustTime, currentDate)) {
    adjustTime = new Date();
  } else if (isEndDateBeforeCurrentDate) {
    adjustTime.setHours(23, 59, 59, 999);
  }
  return adjustTime;
};

export const getLanguageKey = (str) => {
  switch (str) {
    case 'ar':
    case 'arabic':
      return 'arabic';
    case 'es':
    case 'spanish':
      return 'spanish';
    case 'pt':
    case 'portuguese':
      return 'portuguese';
    default:
      return 'english';
  }
};
export const asdName = 'Fazlul'; //need to change the name

export const CountryWithPhoneCode = [
  {
    title: '(+93) 🇦🇫 Afghanistan',
    country: 'Afghanistan',
    code: '+93',
    iso: 'AF',
  },
  {
    title: '(+355) 🇦🇱 Albania',
    country: 'Albania',
    code: '+355',
    iso: 'AL',
  },
  {
    title: '(+213) 🇩🇿 Algeria',
    country: 'Algeria',
    code: '+213',
    iso: 'DZ',
  },
  {
    title: '(+1-684) 🇦🇸 American Samoa',
    country: 'American Samoa',
    code: '+1-684',
    iso: 'AS',
  },
  {
    title: '(+376) 🇦🇩 Andorra',
    country: 'Andorra',
    code: '+376',
    iso: 'AD',
  },
  {
    title: '(+244) 🇦🇴 Angola',
    country: 'Angola',
    code: '+244',
    iso: 'AO',
  },
  {
    title: '(+1-264) 🇦🇮 Anguilla',
    country: 'Anguilla',
    code: '+1-264',
    iso: 'AI',
  },
  {
    title: '(+672) 🇦🇶 Antarctica',
    country: 'Antarctica',
    code: '+672',
    iso: 'AQ',
  },
  {
    title: '(+1-268) 🇦🇬 Antigua and Barbuda',
    country: 'Antigua and Barbuda',
    code: '+1-268',
    iso: 'AG',
  },
  {
    title: '(+54) 🇦🇷 Argentina',
    country: 'Argentina',
    code: '+54',
    iso: 'AR',
  },
  {
    title: '(+374) 🇦🇲 Armenia',
    country: 'Armenia',
    code: '+374',
    iso: 'AM',
  },
  {
    title: '(+297) 🇦🇼 Aruba',
    country: 'Aruba',
    code: '+297',
    iso: 'AW',
  },
  {
    title: '(+61) 🇦🇺 Australia',
    country: 'Australia',
    code: '+61',
    iso: 'AU',
  },
  {
    title: '(+43) 🇦🇹 Austria',
    country: 'Austria',
    code: '+43',
    iso: 'AT',
  },
  {
    title: '(+994) 🇦🇿 Azerbaijan',
    country: 'Azerbaijan',
    code: '+994',
    iso: 'AZ',
  },
  {
    title: '(+1-242) 🇧🇸 Bahamas',
    country: 'Bahamas',
    code: '+1-242',
    iso: 'BS',
  },
  {
    title: '(+973) 🇧🇭 Bahrain',
    country: 'Bahrain',
    code: '+973',
    iso: 'BH',
  },
  {
    title: '(+880) 🇧🇩 Bangladesh',
    country: 'Bangladesh',
    code: '+880',
    iso: 'BD',
  },
  {
    title: '(+1-246) 🇧🇧 Barbados',
    country: 'Barbados',
    code: '+1-246',
    iso: 'BB',
  },
  {
    title: '(+375) 🇧🇾 Belarus',
    country: 'Belarus',
    code: '+375',
    iso: 'BY',
  },
  {
    title: '(+32) 🇧🇪 Belgium',
    country: 'Belgium',
    code: '+32',
    iso: 'BE',
  },
  {
    title: '(+501) 🇧🇿 Belize',
    country: 'Belize',
    code: '+501',
    iso: 'BZ',
  },
  {
    title: '(+229) 🇧🇯 Benin',
    country: 'Benin',
    code: '+229',
    iso: 'BJ',
  },
  {
    title: '(+1-441) 🇧🇲 Bermuda',
    country: 'Bermuda',
    code: '+1-441',
    iso: 'BM',
  },
  {
    title: '(+975) 🇧🇹 Bhutan',
    country: 'Bhutan',
    code: '+975',
    iso: 'BT',
  },
  {
    title: '(+591) 🇧🇴 Bolivia',
    country: 'Bolivia',
    code: '+591',
    iso: 'BO',
  },
  {
    title: '(+387) 🇧🇦 Bosnia and Herzegovina',
    country: 'Bosnia and Herzegovina',
    code: '+387',
    iso: 'BA',
  },
  {
    title: '(+267) 🇧🇼 Botswana',
    country: 'Botswana',
    code: '+267',
    iso: 'BW',
  },
  {
    title: '(+55) 🇧🇷 Brazil',
    country: 'Brazil',
    code: '+55',
    iso: 'BR',
  },
  {
    title: '(+246) 🇮🇴 British Indian Ocean Territory',
    country: 'British Indian Ocean Territory',
    code: '+246',
    iso: 'IO',
  },
  {
    title: '(+1-284) 🇻🇬 British Virgin Islands',
    country: 'British Virgin Islands',
    code: '+1-284',
    iso: 'VG',
  },
  {
    title: '(+673) 🇧🇳 Brunei',
    country: 'Brunei',
    code: '+673',
    iso: 'BN',
  },
  {
    title: '(+359) 🇧🇬 Bulgaria',
    country: 'Bulgaria',
    code: '+359',
    iso: 'BG',
  },
  {
    title: '(+226) 🇧🇫 Burkina Faso',
    country: 'Burkina Faso',
    code: '+226',
    iso: 'BF',
  },
  {
    title: '(+257) 🇧🇮 Burundi',
    country: 'Burundi',
    code: '+257',
    iso: 'BI',
  },
  {
    title: '(+855) 🇰🇭 Cambodia',
    country: 'Cambodia',
    code: '+855',
    iso: 'KH',
  },
  {
    title: '(+237) 🇨🇲 Cameroon',
    country: 'Cameroon',
    code: '+237',
    iso: 'CM',
  },
  {
    title: '(+1) 🇨🇦 Canada',
    country: 'Canada',
    code: '+1',
    iso: 'CA',
  },
  {
    title: '(+238) 🇨🇻 Cape Verde',
    country: 'Cape Verde',
    code: '+238',
    iso: 'CV',
  },
  {
    title: '(+1-345) 🇰🇾 Cayman Islands',
    country: 'Cayman Islands',
    code: '+1-345',
    iso: 'KY',
  },
  {
    title: '(+236) 🇨🇫 Central African Republic',
    country: 'Central African Republic',
    code: '+236',
    iso: 'CF',
  },
  {
    title: '(+235) 🇹🇩 Chad',
    country: 'Chad',
    code: '+235',
    iso: 'TD',
  },
  {
    title: '(+56) 🇨🇱 Chile',
    country: 'Chile',
    code: '+56',
    iso: 'CL',
  },
  {
    title: '(+86) 🇨🇳 China',
    country: 'China',
    code: '+86',
    iso: 'CN',
  },
  {
    title: '(+61) 🇨🇽 Christmas Island',
    country: 'Christmas Island',
    code: '+61',
    iso: 'CX',
  },
  {
    title: '(+61) 🇨🇨 Cocos Islands',
    country: 'Cocos Islands',
    code: '+61',
    iso: 'CC',
  },
  {
    title: '(+57) 🇨🇴 Colombia',
    country: 'Colombia',
    code: '+57',
    iso: 'CO',
  },
  {
    title: '(+269) 🇰🇲 Comoros',
    country: 'Comoros',
    code: '+269',
    iso: 'KM',
  },
  {
    title: '(+682) 🇨🇰 Cook Islands',
    country: 'Cook Islands',
    code: '+682',
    iso: 'CK',
  },
  {
    title: '(+506) 🇨🇷 Costa Rica',
    country: 'Costa Rica',
    code: '+506',
    iso: 'CR',
  },
  {
    title: '(+385) 🇭🇷 Croatia',
    country: 'Croatia',
    code: '+385',
    iso: 'HR',
  },
  {
    title: '(+53) 🇨🇺 Cuba',
    country: 'Cuba',
    code: '+53',
    iso: 'CU',
  },
  {
    title: '(+599) 🇨🇼 Curaçao',
    country: 'Curaçao',
    code: '+599',
    iso: 'CW',
  },
  {
    title: '(+357) 🇨🇾 Cyprus',
    country: 'Cyprus',
    code: '+357',
    iso: 'CY',
  },
  {
    title: '(+420) 🇨🇿 Czech Republic',
    country: 'Czech Republic',
    code: '+420',
    iso: 'CZ',
  },
  {
    title: '(+243) 🇨🇩 Democratic Republic of the Congo',
    country: 'Democratic Republic of the Congo',
    code: '+243',
    iso: 'CD',
  },
  {
    title: '(+45) 🇩🇰 Denmark',
    country: 'Denmark',
    code: '+45',
    iso: 'DK',
  },
  {
    title: '(+253) 🇩🇯 Djibouti',
    country: 'Djibouti',
    code: '+253',
    iso: 'DJ',
  },
  {
    title: '(+1-767) 🇩🇲 Dominica',
    country: 'Dominica',
    code: '+1-767',
    iso: 'DM',
  },
  {
    title: '(+1-809, +1-829, +1-849) 🇩🇴 Dominican Republic',
    country: 'Dominican Republic',
    code: '+1-809, +1-829, +1-849',
    iso: 'DO',
  },
  {
    title: '(+670) 🇹🇱 East Timor',
    country: 'East Timor',
    code: '+670',
    iso: 'TL',
  },
  {
    title: '(+593) 🇪🇨 Ecuador',
    country: 'Ecuador',
    code: '+593',
    iso: 'EC',
  },
  {
    title: '(+20) 🇪🇬 Egypt',
    country: 'Egypt',
    code: '+20',
    iso: 'EG',
  },
  {
    title: '(+503) 🇸🇻 El Salvador',
    country: 'El Salvador',
    code: '+503',
    iso: 'SV',
  },
  {
    title: '(+240) 🇬🇶 Equatorial Guinea',
    country: 'Equatorial Guinea',
    code: '+240',
    iso: 'GQ',
  },
  {
    title: '(+291) 🇪🇷 Eritrea',
    country: 'Eritrea',
    code: '+291',
    iso: 'ER',
  },
  {
    title: '(+372) 🇪🇪 Estonia',
    country: 'Estonia',
    code: '+372',
    iso: 'EE',
  },
  {
    title: '(+251) 🇪🇹 Ethiopia',
    country: 'Ethiopia',
    code: '+251',
    iso: 'ET',
  },
  {
    title: '(+500) 🇫🇰 Falkland Islands',
    country: 'Falkland Islands',
    code: '+500',
    iso: 'FK',
  },
  {
    title: '(+298) 🇫🇴 Faroe Islands',
    country: 'Faroe Islands',
    code: '+298',
    iso: 'FO',
  },
  {
    title: '(+679) 🇫🇯 Fiji',
    country: 'Fiji',
    code: '+679',
    iso: 'FJ',
  },
  {
    title: '(+358) 🇫🇮 Finland',
    country: 'Finland',
    code: '+358',
    iso: 'FI',
  },
  {
    title: '(+33) 🇫🇷 France',
    country: 'France',
    code: '+33',
    iso: 'FR',
  },
  {
    title: '(+594) 🇬🇫 French Guiana',
    country: 'French Guiana',
    code: '+594',
    iso: 'GF',
  },
  {
    title: '(+689) 🇵🇫 French Polynesia',
    country: 'French Polynesia',
    code: '+689',
    iso: 'PF',
  },
  {
    title: '(+241) 🇬🇦 Gabon',
    country: 'Gabon',
    code: '+241',
    iso: 'GA',
  },
  {
    title: '(+220) 🇬🇲 Gambia',
    country: 'Gambia',
    code: '+220',
    iso: 'GM',
  },
  {
    title: '(+995) 🇬🇪 Georgia',
    country: 'Georgia',
    code: '+995',
    iso: 'GE',
  },
  {
    title: '(+49) 🇩🇪 Germany',
    country: 'Germany',
    code: '+49',
    iso: 'DE',
  },
  {
    title: '(+233) 🇬🇭 Ghana',
    country: 'Ghana',
    code: '+233',
    iso: 'GH',
  },
  {
    title: '(+350) 🇬🇮 Gibraltar',
    country: 'Gibraltar',
    code: '+350',
    iso: 'GI',
  },
  {
    title: '(+30) 🇬🇷 Greece',
    country: 'Greece',
    code: '+30',
    iso: 'GR',
  },
  {
    title: '(+299) 🇬🇱 Greenland',
    country: 'Greenland',
    code: '+299',
    iso: 'GL',
  },
  {
    title: '(+1-473) 🇬🇩 Grenada',
    country: 'Grenada',
    code: '+1-473',
    iso: 'GD',
  },
  {
    title: '(+590) 🇬🇵 Guadeloupe',
    country: 'Guadeloupe',
    code: '+590',
    iso: 'GP',
  },
  {
    title: '(+1-671) 🇬🇺 Guam',
    country: 'Guam',
    code: '+1-671',
    iso: 'GU',
  },
  {
    title: '(+502) 🇬🇹 Guatemala',
    country: 'Guatemala',
    code: '+502',
    iso: 'GT',
  },
  {
    title: '(+44) 🇬🇬 Guernsey',
    country: 'Guernsey',
    code: '+44',
    iso: 'GG',
  },
  {
    title: '(+224) 🇬🇳 Guinea',
    country: 'Guinea',
    code: '+224',
    iso: 'GN',
  },
  {
    title: '(+245) 🇬🇼 Guinea-Bissau',
    country: 'Guinea-Bissau',
    code: '+245',
    iso: 'GW',
  },
  {
    title: '(+592) 🇬🇾 Guyana',
    country: 'Guyana',
    code: '+592',
    iso: 'GY',
  },
  {
    title: '(+509) 🇭🇹 Haiti',
    country: 'Haiti',
    code: '+509',
    iso: 'HT',
  },
  {
    title: '(+504) 🇭🇳 Honduras',
    country: 'Honduras',
    code: '+504',
    iso: 'HN',
  },
  {
    title: '(+852) 🇭🇰 Hong Kong',
    country: 'Hong Kong',
    code: '+852',
    iso: 'HK',
  },
  {
    title: '(+36) 🇭🇺 Hungary',
    country: 'Hungary',
    code: '+36',
    iso: 'HU',
  },
  {
    title: '(+354) 🇮🇸 Iceland',
    country: 'Iceland',
    code: '+354',
    iso: 'IS',
  },
  {
    title: '(+91) 🇮🇳 India',
    country: 'India',
    code: '+91',
    iso: 'IN',
  },
  {
    title: '(+62) 🇮🇩 Indonesia',
    country: 'Indonesia',
    code: '+62',
    iso: 'ID',
  },
  {
    title: '(+98) 🇮🇷 Iran',
    country: 'Iran',
    code: '+98',
    iso: 'IR',
  },
  {
    title: '(+964) 🇮🇶 Iraq',
    country: 'Iraq',
    code: '+964',
    iso: 'IQ',
  },
  {
    title: '(+353) 🇮🇪 Ireland',
    country: 'Ireland',
    code: '+353',
    iso: 'IE',
  },
  {
    title: '(+44) 🇮🇲 Isle of Man',
    country: 'Isle of Man',
    code: '+44',
    iso: 'IM',
  },
  {
    title: '(+972) 🇮🇱 Israel',
    country: 'Israel',
    code: '+972',
    iso: 'IL',
  },
  {
    title: '(+39) 🇮🇹 Italy',
    country: 'Italy',
    code: '+39',
    iso: 'IT',
  },
  {
    title: '(+225) 🇨🇮 Ivory Coast',
    country: 'Ivory Coast',
    code: '+225',
    iso: 'CI',
  },
  {
    title: '(+1-876) 🇯🇲 Jamaica',
    country: 'Jamaica',
    code: '+1-876',
    iso: 'JM',
  },
  {
    title: '(+81) 🇯🇵 Japan',
    country: 'Japan',
    code: '+81',
    iso: 'JP',
  },
  {
    title: '(+44) 🇯🇪 Jersey',
    country: 'Jersey',
    code: '+44',
    iso: 'JE',
  },
  {
    title: '(+962) 🇯🇴 Jordan',
    country: 'Jordan',
    code: '+962',
    iso: 'JO',
  },
  {
    title: '(+7) 🇰🇿 Kazakhstan',
    country: 'Kazakhstan',
    code: '+7',
    iso: 'KZ',
  },
  {
    title: '(+254) 🇰🇪 Kenya',
    country: 'Kenya',
    code: '+254',
    iso: 'KE',
  },
  {
    title: '(+686) 🇰🇮 Kiribati',
    country: 'Kiribati',
    code: '+686',
    iso: 'KI',
  },
  {
    title: '(+383) 🇽🇰 Kosovo',
    country: 'Kosovo',
    code: '+383',
    iso: 'XK',
  },
  {
    title: '(+965) 🇰🇼 Kuwait',
    country: 'Kuwait',
    code: '+965',
    iso: 'KW',
  },
  {
    title: '(+996) 🇰🇬 Kyrgyzstan',
    country: 'Kyrgyzstan',
    code: '+996',
    iso: 'KG',
  },
  {
    title: '(+856) 🇱🇦 Laos',
    country: 'Laos',
    code: '+856',
    iso: 'LA',
  },
  {
    title: '(+371) 🇱🇻 Latvia',
    country: 'Latvia',
    code: '+371',
    iso: 'LV',
  },
  {
    title: '(+961) 🇱🇧 Lebanon',
    country: 'Lebanon',
    code: '+961',
    iso: 'LB',
  },
  {
    title: '(+266) 🇱🇸 Lesotho',
    country: 'Lesotho',
    code: '+266',
    iso: 'LS',
  },
  {
    title: '(+231) 🇱🇷 Liberia',
    country: 'Liberia',
    code: '+231',
    iso: 'LR',
  },
  {
    title: '(+218) 🇱🇾 Libya',
    country: 'Libya',
    code: '+218',
    iso: 'LY',
  },
  {
    title: '(+423) 🇱🇮 Liechtenstein',
    country: 'Liechtenstein',
    code: '+423',
    iso: 'LI',
  },
  {
    title: '(+370) 🇱🇹 Lithuania',
    country: 'Lithuania',
    code: '+370',
    iso: 'LT',
  },
  {
    title: '(+352) 🇱🇺 Luxembourg',
    country: 'Luxembourg',
    code: '+352',
    iso: 'LU',
  },
  {
    title: '(+853) 🇲🇴 Macao',
    country: 'Macao',
    code: '+853',
    iso: 'MO',
  },
  {
    title: '(+389) 🇲🇰 Macedonia',
    country: 'Macedonia',
    code: '+389',
    iso: 'MK',
  },
  {
    title: '(+261) 🇲🇬 Madagascar',
    country: 'Madagascar',
    code: '+261',
    iso: 'MG',
  },
  {
    title: '(+265) 🇲🇼 Malawi',
    country: 'Malawi',
    code: '+265',
    iso: 'MW',
  },
  {
    title: '(+60) 🇲🇾 Malaysia',
    country: 'Malaysia',
    code: '+60',
    iso: 'MY',
  },
  {
    title: '(+960) 🇲🇻 Maldives',
    country: 'Maldives',
    code: '+960',
    iso: 'MV',
  },
  {
    title: '(+223) 🇲🇱 Mali',
    country: 'Mali',
    code: '+223',
    iso: 'ML',
  },
  {
    title: '(+356) 🇲🇹 Malta',
    country: 'Malta',
    code: '+356',
    iso: 'MT',
  },
  {
    title: '(+692) 🇲🇭 Marshall Islands',
    country: 'Marshall Islands',
    code: '+692',
    iso: 'MH',
  },
  {
    title: '(+596) 🇲🇶 Martinique',
    country: 'Martinique',
    code: '+596',
    iso: 'MQ',
  },
  {
    title: '(+222) 🇲🇷 Mauritania',
    country: 'Mauritania',
    code: '+222',
    iso: 'MR',
  },
  {
    title: '(+230) 🇲🇺 Mauritius',
    country: 'Mauritius',
    code: '+230',
    iso: 'MU',
  },
  {
    title: '(+262) 🇾🇹 Mayotte',
    country: 'Mayotte',
    code: '+262',
    iso: 'YT',
  },
  {
    title: '(+52) 🇲🇽 Mexico',
    country: 'Mexico',
    code: '+52',
    iso: 'MX',
  },
  {
    title: '(+691) 🇫🇲 Micronesia',
    country: 'Micronesia',
    code: '+691',
    iso: 'FM',
  },
  {
    title: '(+373) 🇲🇩 Moldova',
    country: 'Moldova',
    code: '+373',
    iso: 'MD',
  },
  {
    title: '(+377) 🇲🇨 Monaco',
    country: 'Monaco',
    code: '+377',
    iso: 'MC',
  },
  {
    title: '(+976) 🇲🇳 Mongolia',
    country: 'Mongolia',
    code: '+976',
    iso: 'MN',
  },
  {
    title: '(+382) 🇲🇪 Montenegro',
    country: 'Montenegro',
    code: '+382',
    iso: 'ME',
  },
  {
    title: '(+1-664) 🇲🇸 Montserrat',
    country: 'Montserrat',
    code: '+1-664',
    iso: 'MS',
  },
  {
    title: '(+212) 🇲🇦 Morocco',
    country: 'Morocco',
    code: '+212',
    iso: 'MA',
  },
  {
    title: '(+258) 🇲🇿 Mozambique',
    country: 'Mozambique',
    code: '+258',
    iso: 'MZ',
  },
  {
    title: '(+95) 🇲🇲 Myanmar',
    country: 'Myanmar',
    code: '+95',
    iso: 'MM',
  },
  {
    title: '(+264) 🇳🇦 Namibia',
    country: 'Namibia',
    code: '+264',
    iso: 'NA',
  },
  {
    title: '(+674) 🇳🇷 Nauru',
    country: 'Nauru',
    code: '+674',
    iso: 'NR',
  },
  {
    title: '(+977) 🇳🇵 Nepal',
    country: 'Nepal',
    code: '+977',
    iso: 'NP',
  },
  {
    title: '(+31) 🇳🇱 Netherlands',
    country: 'Netherlands',
    code: '+31',
    iso: 'NL',
  },
  {
    title: '(+687) 🇳🇨 New Caledonia',
    country: 'New Caledonia',
    code: '+687',
    iso: 'NC',
  },
  {
    title: '(+64) 🇳🇿 New Zealand',
    country: 'New Zealand',
    code: '+64',
    iso: 'NZ',
  },
  {
    title: '(+505) 🇳🇮 Nicaragua',
    country: 'Nicaragua',
    code: '+505',
    iso: 'NI',
  },
  {
    title: '(+227) 🇳🇪 Niger',
    country: 'Niger',
    code: '+227',
    iso: 'NE',
  },
  {
    title: '(+234) 🇳🇬 Nigeria',
    country: 'Nigeria',
    code: '+234',
    iso: 'NG',
  },
  {
    title: '(+683) 🇳🇺 Niue',
    country: 'Niue',
    code: '+683',
    iso: 'NU',
  },
  {
    title: '(+672) 🇳🇫 Norfolk Island',
    country: 'Norfolk Island',
    code: '+672',
    iso: 'NF',
  },
  {
    title: '(+850) 🇰🇵 North Korea',
    country: 'North Korea',
    code: '+850',
    iso: 'KP',
  },
  {
    title: '(+1-670) 🇲🇵 Northern Mariana Islands',
    country: 'Northern Mariana Islands',
    code: '+1-670',
    iso: 'MP',
  },
  {
    title: '(+47) 🇸🇯 Norway',
    country: 'Norway',
    code: '+47',
    iso: 'NO',
  },
  {
    title: '(+968) 🇴🇲 Oman',
    country: 'Oman',
    code: '+968',
    iso: 'OM',
  },
  {
    title: '(+92) 🇵🇰 Pakistan',
    country: 'Pakistan',
    code: '+92',
    iso: 'PK',
  },
  {
    title: '(+680) 🇵🇼 Palau',
    country: 'Palau',
    code: '+680',
    iso: 'PW',
  },
  {
    title: '(+970) 🇵🇸 Palestine',
    country: 'Palestine',
    code: '+970',
    iso: 'PS',
  },
  {
    title: '(+507) 🇵🇦 Panama',
    country: 'Panama',
    code: '+507',
    iso: 'PA',
  },
  {
    title: '(+675) 🇵🇬 Papua New Guinea',
    country: 'Papua New Guinea',
    code: '+675',
    iso: 'PG',
  },
  {
    title: '(+595) 🇵🇾 Paraguay',
    country: 'Paraguay',
    code: '+595',
    iso: 'PY',
  },
  {
    title: '(+51) 🇵🇪 Peru',
    country: 'Peru',
    code: '+51',
    iso: 'PE',
  },
  {
    title: '(+63) 🇵🇭 Philippines',
    country: 'Philippines',
    code: '+63',
    iso: 'PH',
  },
  {
    title: '(+48) 🇵🇱 Poland',
    country: 'Poland',
    code: '+48',
    iso: 'PL',
  },
  {
    title: '(+351) 🇵🇹 Portugal',
    country: 'Portugal',
    code: '+351',
    iso: 'PT',
  },
  {
    title: '(+1-787, +1-939) 🇵🇷 Puerto Rico',
    country: 'Puerto Rico',
    code: '+1-787, +1-939',
    iso: 'PR',
  },
  {
    title: '(+974) 🇶🇦 Qatar',
    country: 'Qatar',
    code: '+974',
    iso: 'QA',
  },
  {
    title: '(+242) 🇨🇬 Republic of the Congo',
    country: 'Republic of the Congo',
    code: '+242',
    iso: 'CG',
  },
  {
    title: '(+262) 🇷🇪 Réunion',
    country: 'Réunion',
    code: '+262',
    iso: 'RE',
  },
  {
    title: '(+40) 🇷🇴 Romania',
    country: 'Romania',
    code: '+40',
    iso: 'RO',
  },
  {
    title: '(+7) 🇷🇺 Russia',
    country: 'Russia',
    code: '+7',
    iso: 'RU',
  },
  {
    title: '(+250) 🇷🇼 Rwanda',
    country: 'Rwanda',
    code: '+250',
    iso: 'RW',
  },
  {
    title: '(+590) 🇧🇱 Saint Barthélemy',
    country: 'Saint Barthélemy',
    code: '+590',
    iso: 'BL',
  },
  {
    title: '(+290) 🇸🇭 Saint Helena',
    country: 'Saint Helena',
    code: '+290',
    iso: 'SH',
  },
  {
    title: '(+1-869) 🇰🇳 Saint Kitts and Nevis',
    country: 'Saint Kitts and Nevis',
    code: '+1-869',
    iso: 'KN',
  },
  {
    title: '(+1-758) 🇱🇨 Saint Lucia',
    country: 'Saint Lucia',
    code: '+1-758',
    iso: 'LC',
  },
  {
    title: '(+590) 🇲🇫 Saint Martin',
    country: 'Saint Martin',
    code: '+590',
    iso: 'MF',
  },
  {
    title: '(+508) 🇵🇲 Saint Pierre and Miquelon',
    country: 'Saint Pierre and Miquelon',
    code: '+508',
    iso: 'PM',
  },
  {
    title: '(+1-784) 🇻🇨 Saint Vincent and the Grenadines',
    country: 'Saint Vincent and the Grenadines',
    code: '+1-784',
    iso: 'VC',
  },
  {
    title: '(+685) 🇼🇸 Samoa',
    country: 'Samoa',
    code: '+685',
    iso: 'WS',
  },
  {
    title: '(+378) 🇸🇲 San Marino',
    country: 'San Marino',
    code: '+378',
    iso: 'SM',
  },
  {
    title: '(+239) 🇸🇹 Sao Tome and Principe',
    country: 'Sao Tome and Principe',
    code: '+239',
    iso: 'ST',
  },
  {
    title: '(+966) 🇸🇦 Saudi Arabia',
    country: 'Saudi Arabia',
    code: '+966',
    iso: 'SA',
  },
  {
    title: '(+221) 🇸🇳 Senegal',
    country: 'Senegal',
    code: '+221',
    iso: 'SN',
  },
  {
    title: '(+381) 🇷🇸 Serbia',
    country: 'Serbia',
    code: '+381',
    iso: 'RS',
  },
  {
    title: '(+248) 🇸🇨 Seychelles',
    country: 'Seychelles',
    code: '+248',
    iso: 'SC',
  },
  {
    title: '(+232) 🇸🇱 Sierra Leone',
    country: 'Sierra Leone',
    code: '+232',
    iso: 'SL',
  },
  {
    title: '(+65) 🇸🇬 Singapore',
    country: 'Singapore',
    code: '+65',
    iso: 'SG',
  },
  {
    title: '(+1-721) 🇸🇽 Sint Maarten',
    country: 'Sint Maarten',
    code: '+1-721',
    iso: 'SX',
  },
  {
    title: '(+421) 🇸🇰 Slovakia',
    country: 'Slovakia',
    code: '+421',
    iso: 'SK',
  },
  {
    title: '(+386) 🇸🇮 Slovenia',
    country: 'Slovenia',
    code: '+386',
    iso: 'SI',
  },
  {
    title: '(+677) 🇸🇧 Solomon Islands',
    country: 'Solomon Islands',
    code: '+677',
    iso: 'SB',
  },
  {
    title: '(+252) 🇸🇴 Somalia',
    country: 'Somalia',
    code: '+252',
    iso: 'SO',
  },
  {
    title: '(+27) 🇿🇦 South Africa',
    country: 'South Africa',
    code: '+27',
    iso: 'ZA',
  },
  {
    title: '(+82) 🇰🇷 South Korea',
    country: 'South Korea',
    code: '+82',
    iso: 'KR',
  },
  {
    title: '(+211) 🇸🇸 South Sudan',
    country: 'South Sudan',
    code: '+211',
    iso: 'SS',
  },
  {
    title: '(+34) 🇪🇸 Spain',
    country: 'Spain',
    code: '+34',
    iso: 'ES',
  },
  {
    title: '(+94) 🇱🇰 Sri Lanka',
    country: 'Sri Lanka',
    code: '+94',
    iso: 'LK',
  },
  {
    title: '(+249) 🇸🇩 Sudan',
    country: 'Sudan',
    code: '+249',
    iso: 'SD',
  },
  {
    title: '(+597) 🇸🇷 Suriname',
    country: 'Suriname',
    code: '+597',
    iso: 'SR',
  },
  {
    title: '(+47) 🇸🇯 Svalbard and Jan Mayen',
    country: 'Svalbard and Jan Mayen',
    code: '+47',
    iso: 'SJ',
  },
  {
    title: '(+268) 🇸🇿 Swaziland',
    country: 'Swaziland',
    code: '+268',
    iso: 'SZ',
  },
  {
    title: '(+46) 🇸🇪 Sweden',
    country: 'Sweden',
    code: '+46',
    iso: 'SE',
  },
  {
    title: '(+41) 🇨🇭 Switzerland',
    country: 'Switzerland',
    code: '+41',
    iso: 'CH',
  },
  {
    title: '(+963) 🇸🇾 Syria',
    country: 'Syria',
    code: '+963',
    iso: 'SY',
  },
  {
    title: '(+886) 🇹🇼 Taiwan',
    country: 'Taiwan',
    code: '+886',
    iso: 'TW',
  },
  {
    title: '(+992) 🇹🇯 Tajikistan',
    country: 'Tajikistan',
    code: '+992',
    iso: 'TJ',
  },
  {
    title: '(+255) 🇹🇿 Tanzania',
    country: 'Tanzania',
    code: '+255',
    iso: 'TZ',
  },
  {
    title: '(+66) 🇹🇭 Thailand',
    country: 'Thailand',
    code: '+66',
    iso: 'TH',
  },
  {
    title: '(+670) 🇹🇱 Timor-Leste',
    country: 'Timor-Leste',
    code: '+670',
    iso: 'TL',
  },
  {
    title: '(+228) 🇹🇬 Togo',
    country: 'Togo',
    code: '+228',
    iso: 'TG',
  },
  {
    title: '(+690) 🇹🇰 Tokelau',
    country: 'Tokelau',
    code: '+690',
    iso: 'TK',
  },
  {
    title: '(+676) 🇹🇴 Tonga',
    country: 'Tonga',
    code: '+676',
    iso: 'TO',
  },
  {
    title: '(+1-868) 🇹🇹 Trinidad and Tobago',
    country: 'Trinidad and Tobago',
    code: '+1-868',
    iso: 'TT',
  },
  {
    title: '(+216) 🇹🇳 Tunisia',
    country: 'Tunisia',
    code: '+216',
    iso: 'TN',
  },
  {
    title: '(+90) 🇹🇷 Turkey',
    country: 'Turkey',
    code: '+90',
    iso: 'TR',
  },
  {
    title: '(+993) 🇹🇲 Turkmenistan',
    country: 'Turkmenistan',
    code: '+993',
    iso: 'TM',
  },
  {
    title: '(+1-649) 🇹🇨 Turks and Caicos Islands',
    country: 'Turks and Caicos Islands',
    code: '+1-649',
    iso: 'TC',
  },
  {
    title: '(+688) 🇹🇻 Tuvalu',
    country: 'Tuvalu',
    code: '+688',
    iso: 'TV',
  },
  {
    title: '(+1-340) 🇻🇮 U.S. Virgin Islands',
    country: 'U.S. Virgin Islands',
    code: '+1-340',
    iso: 'VI',
  },
  {
    title: '(+256) 🇺🇬 Uganda',
    country: 'Uganda',
    code: '+256',
    iso: 'UG',
  },
  {
    title: '(+380) 🇺🇦 Ukraine',
    country: 'Ukraine',
    code: '+380',
    iso: 'UA',
  },
  {
    title: '(+971) 🇦🇪 United Arab Emirates',
    country: 'United Arab Emirates',
    code: '+971',
    iso: 'AE',
  },
  {
    title: '(+44) 🇬🇧 United Kingdom',
    country: 'United Kingdom',
    code: '+44',
    iso: 'GB',
  },
  {
    title: '(+1) 🇺🇸 United States',
    country: 'United States',
    code: '+1',
    iso: 'US',
  },
  {
    title: '(+598) 🇺🇾 Uruguay',
    country: 'Uruguay',
    code: '+598',
    iso: 'UY',
  },
  {
    title: '(+998) 🇺🇿 Uzbekistan',
    country: 'Uzbekistan',
    code: '+998',
    iso: 'UZ',
  },
  {
    title: '(+678) 🇻🇺 Vanuatu',
    country: 'Vanuatu',
    code: '+678',
    iso: 'VU',
  },
  {
    title: '(+379) 🇻🇦 Vatican City',
    country: 'Vatican City',
    code: '+379',
    iso: 'VA',
  },
  {
    title: '(+58) 🇻🇪 Venezuela',
    country: 'Venezuela',
    code: '+58',
    iso: 'VE',
  },
  {
    title: '(+84) 🇻🇳 Vietnam',
    country: 'Vietnam',
    code: '+84',
    iso: 'VN',
  },
  {
    title: '(+681) 🇼🇫 Wallis and Futuna',
    country: 'Wallis and Futuna',
    code: '+681',
    iso: 'WF',
  },
  {
    title: '(+212) 🇪🇭 Western Sahara',
    country: 'Western Sahara',
    code: '+212',
    iso: 'EH',
  },
  {
    title: '(+967) 🇾🇪 Yemen',
    country: 'Yemen',
    code: '+967',
    iso: 'YE',
  },
  {
    title: '(+260) 🇿🇲 Zambia',
    country: 'Zambia',
    code: '+260',
    iso: 'ZM',
  },
  {
    title: '(+263) 🇿🇼 Zimbabwe',
    country: 'Zimbabwe',
    code: '+263',
    iso: 'ZW',
  },
];
